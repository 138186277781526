import React, { useMemo } from 'react';

import Button from 'Components/Common/Button';
import Features from 'Components/Common/Features';

import styles from "./styles.module.scss"
import Heading from '../../../Common/Heading';
import WorkHoursFeature from '../../../Common/Features/WorkHoursFeature';
import Loader from '../../../Common/Loader';
import tigraLogo from "assets/images/tigra_logo.svg"
import { guarantorNote } from '..';

const UnitApplicationFormHome = ({ onNext, unit, }) => {
    const { unitFeatures, managerFeatures } = useMemo(() => getFeatures(unit), [unit]);

    return (
        <div className={styles.container}>
            <img className={styles.logo} src={tigraLogo} />
            <Loader isLoading={!unit?.unitNumber}>
                <Heading text={`Unit ${unit?.unitNumber}`} Tag="h1" />
                <Features
                    heading={"Property Details"}
                    features={unitFeatures}
                    containerClass={styles.featuresContainer}
                    displayBorder
                />
                <Features
                    heading={"Contact information"}
                    features={managerFeatures}
                    containerClass={styles.featuresContainer}
                    displayBorder
                />
                {guarantorNote}
                <div className={styles.btnContainer}>
                    <Button className={styles.btn} onClick={onNext}>Next</Button>
                </div>
            </Loader>
        </div>
    )
};


const getFeatures = (data) => {
    const manager = data?.building?.manager;
    return {
        unitFeatures: [
            { label: "Availability Date", value: data?.vacancyDate },
            { label: "Property Address ", value: data?.building?.fullAddress },
            { label: "Rent Amount ", value: "$" + data?.price },
        ],
        managerFeatures: [
            { label: "Manager Name ", value: manager?.fullName },
            { label: "Email", value: manager?.email },
            { label: "Phone Number", value: manager?.phone },
            { label: "Hours", value: manager?.workHours && <WorkHoursFeature {...manager?.workHours} /> }
        ]
    }
}


export default UnitApplicationFormHome;