import { gql } from 'graphql-request';

export const registerUserGql = gql`
mutation register ($data: RegistrationInput!){
  registerUser(input: {data: $data}) {
    success
  }
}
`
export const changePasswordGql = gql`
mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(
    input: {currentPassword: $currentPassword, newPassword: $newPassword}
  ) {
    success
  }
}
`
export const loginGql = gql`
mutation Login($email: String!, $password: String!) {
  tokenAuth(input: {email: $email, password: $password}) {
    token
    user {
      fullName
    }
  }
}
`