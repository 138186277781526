import React, { useEffect, useState } from 'react';
import MultiPartFormContainer from 'Components/MultiPartContainer';
import styles from "../styles.module.scss"
import { CheckBox } from "Components/Common/TabListSelect/TabListButton";
import check from '../../../../assets/images/chechbox.svg'
import Modal from './Modal';
import GuarantorFields from "./guarantorField";
import TenantFields from "./tenantField";
import { useIsOpen } from 'hooks/useIsOpen';
import { useHistory } from 'react-router-dom';

export const BuildingAddress = ({address}) => <p className={styles.address}>{address}</p>

const UnitApplicationFormAttachments = ({ onPrev, setValue, values, onSubmit, heading, unitAddress, isSuccess, headingPrepender }) => {
    const [isGuarantorDoc, setIsGuarantorDoc] = useState(false);
    const { isOpen: isConfirmationModalOpen, open: openConfirmationModal, close: closeConfirmationModal } = useIsOpen()
    const { isOpen: isSuccessModalOpen, open: openSuccessModal, close: closeSuccessModal } = useIsOpen();
    const { isOpen: displayLoader, open: openLoader, close: closeLoader } = useIsOpen();
    const history = useHistory();

    useEffect(() => {
        closeLoader()
        if (!isSuccess) return
        closeConfirmationModal()
        openSuccessModal()
    }, [isSuccess]);

    const handleSubmit = () => {
        openLoader()
        onSubmit(values)
    }

    const handleCloseSuccess = () => {
        closeSuccessModal()
        history.push("/")
    };

    return (
        <MultiPartFormContainer
            containerClass={styles.formSectionContainer}
            onNext={openConfirmationModal}
            onPrev={onPrev}
            heading={heading}
            nextText="Submit"
            headingPrepender={headingPrepender}
        >
            <BuildingAddress address={unitAddress} />
            <p className={styles.rentalDescription}>
                All documents should be submitted within five (5) calendar days of submission of initial deposit. If additional time is needed, please contact the leasing agent or management company and let them know when the delayd documents can be expected. All documents are to be delivered, mailed, or emailed to the address below.
            </p>
            <TenantFields setValue={setValue} values={values} />
            <p className={styles.importantNote}>
                <strong>Important note:</strong> If you have a guarantor for this application, please have their filled out application and necessary documents ready to upload at the end of this application
            </p>
            <div className={styles.guarantorDoc} onClick={() => {
                setIsGuarantorDoc(!isGuarantorDoc);
            }}>
                <CheckBox isChecked={isGuarantorDoc} icon={<img src={check} />} />
                <p>Add Guarantor documents</p>
            </div>
            {isGuarantorDoc && <GuarantorFields setValue={setValue} values={values} />}
            {isConfirmationModalOpen && (
                <Modal
                    title='Terms and service acception'
                    desription='TGM Services Corp. is hereby authorized to obtain any and all information they deem necessary for the processing of my rental application, including but not limited to credit reports, civil and criminal background checks, prior rental history, current and past employment history, motor vehicle records, etc. I understand that the rental application fee is non-refundable even if my rental application is denied and that the information on this form may be maintained in a tenant data base for up to 7 years after I vacate the property. I hereby authorize TGM Services Corp. to release any of the information that it obtains to the prospective landlord and any dual or cooperating real estate agent. I represent and certify that the information that I have furnished to TGM Services Corp. and the prospective landlord is true, pursuant to federal, state, and local law of New York.'
                    checkBoxLabel='I accept'
                    buttonLabel='Apply'
                    isLoading={displayLoader}
                    isCheckBox
                    isClose
                    onClickClose={closeConfirmationModal}
                    onSubmit={handleSubmit}
                />
            )}
            {isSuccessModalOpen && (
                <Modal
                    title='Thank you for submitting your application!'
                    subTitle='Please check your e-mail for confirmation and an invite to join the Tigra app.'
                    desription='Through our app, you will be able to view your application status, communicate with the management company and share any other necessary documents regarding your application.'
                    buttonLabel='Close'
                    onSubmit={handleCloseSuccess}
                />
            )}
        </MultiPartFormContainer>
    )
};

export default UnitApplicationFormAttachments


