const menus = [{
  name: 'About',
  url: '/#about'
},
{
  name: 'Features',
  url: "/#features"
},
{
  name: 'Pricing',
  url: '/#plans'
},
{
  name: 'Meet the founder',
  url: '/#meetTheFounder'
},
{
  name: 'Say hi!',
  url: "/#contactUs"
},
{
  name: 'Sign In',
  url: '/signin',
  isPathExist: true
}]
export default menus