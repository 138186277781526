import React from 'react';


const Button = ({ children, onClick, ...props }) => {
    return (
        <button onClick={onClick} {...props}>
            {children}
        </button>
    )
}

export default Button