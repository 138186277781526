import React from "react";
import classes from "classnames"
import styles from './styles.module.scss';
import Heading from "../Heading";

const Features = ({features=[], containerClass, isColumn = true, displayBorder = true, heading}) => {
    
    return (
        <div className={classes({[styles.featuresColumn]: isColumn, [styles.featuresRow]: !isColumn, [styles.borders]: displayBorder}, containerClass)}>
            {heading && <Heading text={heading} />}
            {features.map(({label, value, type, className}, index) => value && (<div className={classes(styles.feature, className)} key={index}>
                {label && <span className={styles.name}>{label}</span>}
                <span>
                    {type ==="link" ? <a href={value} target="_blank">{value}</a> : value}
                </span>
            </div>))}
        </div>
    );
};

export default Features