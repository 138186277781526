import React from 'react';
import styles from './styles.module.scss';
import divider from 'assets/images/divider.svg'
import Plan from './Plan';
import { isMobile } from 'hooks/useIsMobile';
import MobileCarousel from './MobileCarousel';


const ComingSoon = () => {
   return (
      <section id="plans" className={styles.root}>
         <span className={styles.header}>Coming Soon</span>
         <img src={divider} className={styles.divider} alt="" />
         <div className={styles.plans}>
            {isMobile ? 
               <MobileCarousel />
               :
               <>
                  <Plan planName="BASIC" price="Free" conditions={[{ amount: 100, content: 'connections' }, { amount: 3, content: 'features' }, { amount: 10, content: 'requests per month' }]} />
                  <Plan planName="PREMIUM" price="$50" smallPriceText="/month" conditions={[{ amount: 100, content: 'connections' }, { amount: 3, content: 'features' }, { amount: 10, content: 'requests per month' }]} />
                  <Plan planName="PRO" price="$100" smallPriceText="/month" conditions={[{ amount: 100, content: 'connections' }, { amount: 3, content: 'features' }, { amount: 10, content: 'requests per month' }]} />
               </>
               
            }
         </div>
         <a href="#contactUs" className={styles.button}>Schedule a Demo</a>
      </section>);
};

export default ComingSoon; 