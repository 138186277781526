import React from 'react';
import styles from './styles.module.scss';
import ToggleSwitch from 'Components/Common/toggleSwitch';
import Input from 'Components/Common/Input';
import ApplicationSubmittedModal from '../Modals/ApplicationSubmitted';
import { useInput } from 'hooks/useInput';
import { USER_TYPES } from 'const/users';
import ResetTemporaryPasswordModals from './ResetTemporaryPasswordModals';
import { useHistory } from 'react-router-dom';
import classes from 'classnames';

const Form = ({ toggleIsManager, isManager, registerUser, isSuccess,closeSuccessModal }) => {
    const [firstNameProps, firstName] = useInput({ placeholder: "First Name" });
    const [lastNameProps, lastName] = useInput({ placeholder: "Last Name" });
    const [emailProps, email] = useInput();
    const [phoneProps, phone] = useInput();
    const [companyNameProps, companyName] = useInput();
    const history = useHistory()
    const inputClass = classes({[styles.input]: true, [styles.mt]: true, [styles.inputstyle]: true});

    const handleSubmit = () => registerUser({
        firstName, 
        email, 
        lastName, 
        phone, 
        companyName, 
        userType: USER_TYPES[isManager ? "MANAGEMENT" : "LANDLORD"]
    });

    const handleClose = () => {
        closeSuccessModal()
        history.push("/")
    }

    return (
        <>
            <p className={styles.header}>Sign Up</p>
            <div className={styles.toggle}><ToggleSwitch leftText={'MANAGER'} rightText={'Landlord'} onChange={toggleIsManager} /></div>
            <div className={styles.inputs}>
                <div className={styles.RowDiv}>
                    <Input {...firstNameProps} inputStyle={styles.smallInput} />
                    <Input {...lastNameProps} inputStyle={styles.smallInput} />
                </div>
                    {isManager && <Input className={inputClass} placeholder="Company Name" {...companyNameProps} />}
                    <Input className={inputClass} placeholder="Email" type="email" {...emailProps} />
                    <Input className={inputClass} placeholder="Phone" {...phoneProps} />
                <button className={styles.button} onClick={handleSubmit}> Submit </button>
            </div>
            {isSuccess && <ApplicationSubmittedModal close={handleClose} OpenEmailClicked={handleClose} />}
            <ResetTemporaryPasswordModals />
        </>
    );
};

export default Form;