import React from 'react';
import AttachmentField from 'Components/Common/Form/Fields/Attachmentfield';
import Heading from 'Components/Common/Heading';
import styles from "../styles.module.scss"

const TenantFields = ({ values, setValue }) => {
    return <div>
        <Heading text={"Tenant Documents"} className={styles.sectionSubheading} />
        <p className={styles.subDescription}>Tenant must make at least 40X the rent or greater. Incomes may be combined if needed. Failure to meet this requirement will require a guarantor or the lease term to be paid in full.</p>
        <p className={styles.subDescription}>All documents can be in format such as JPG, PNG, PDF.</p>
        <AttachmentField label={"Signed DOS form"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantSignedDOSForm" value={values?.["tenantSignedDOSForm"]} multiple />
        <AttachmentField label={"Most Recent Federal Tax return, W-2 or letter from CPA"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantCPALetter" value={values?.["tenantCPALetter"]} multiple />
        <AttachmentField label={"Letter of employment/ Job offer letter/ Student transcript"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantLetter" value={values?.["tenantLetter"]} multiple />
        <AttachmentField label={"3 Recent pay stubs"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantPayStubs" value={values?.["tenantPayStubs"]} multiple />
        <AttachmentField label={"Most Recent bank account statements showing balance"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantBankStatements" value={values?.["tenantBankStatements"]} multiple />
        <AttachmentField label={"Letter of Reference from current landlord and Landlord's contact information"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantLandlordReference" value={values?.["tenantLandlordReference"]} multiple />
        <AttachmentField label={"Full Credit Report"} rightLabel={"Optional"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantCreditReport" value={values?.["tenantCreditReport"]} multiple />
        <AttachmentField label={"Photo ID"} setValue={(fieldName, files) => setValue(fieldName, files)} fieldName="tenantPhotoID" value={values?.["tenantPhotoID"]} multiple />
    </div>
}

export default TenantFields;