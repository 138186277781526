import React from 'react';
import styles from './styles.module.scss';
import divider from 'assets/images/Secondary2Divider.svg';
import talGrafi from 'assets/images/TalGrafi.png';
import { founderTexts } from './texts'

const MeetTheFounder = () => {
   return (
      <div className={styles.root} id="meetTheFounder">
         <div></div>
         <div className={styles.headerWrapper}>
               <span className={styles.header}>Meet the Founder </span>
               <img src={divider} className={styles.divider} alt="" />
         </div>
         <div className={styles.talImageWrap}>
            <img className={styles.talImage} src={talGrafi} alt="" />
         </div>
         <section className={styles.content}>
            <div className={styles.textWrapper}>
               {founderTexts.map((text)=>(
                  <p>
                     <span className={styles.textSpan}>{text}</span>
                  </p>
               ))}
     
            </div>
         </section>
      </div >);
};

export default MeetTheFounder;