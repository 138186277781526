import { debounce } from "lodash";
import { useLayoutEffect, useState } from "react";

const mobileWidth = 600;

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
  
    useLayoutEffect(() => {
      const updateSize = () => {
        setIsMobile(window.innerWidth < mobileWidth);
      };
      window.addEventListener('resize', debounce(updateSize, 250));

      return () => window.removeEventListener('resize', updateSize);
    }, []);
  
    return isMobile;
  };
  
export const isMobile = window.matchMedia(`only screen and (max-width: ${mobileWidth}px)`).matches;
