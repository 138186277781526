import React from 'react';
import styles from './styles.module.scss';
import classes from 'classnames';

const Backdrop = ({ close, transparent = false }) => {
    return (
        <div className={classes({
            [styles.clearBackdrop]: transparent,
            [styles.backdrop]: !transparent
        })}
            onClick={close}
        />
    )
};

export default Backdrop;