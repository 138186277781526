import React from 'react';
import styles from './styles.module.scss';

const FeaturesGrid = ({ icon, title, content }) => {
   return (
      <div className={styles.root}>
         <img src={icon} className={styles.icon} alt="" />
         <span className={styles.title}>{title} </span>
         <span className={styles.content}>
            {content}
         </span>
      </div >);
};

export default FeaturesGrid;