import React from 'react';
import { Formik } from 'formik';
import styles from './styles.module.scss';
import { Divider } from 'Components/Common/Divider';
import Input from 'Components/Common/Input';
import FileInput from 'Components/Common/FileInput';
import PaperClip from 'assets/images/paperClip.svg';
import profileIcon from 'assets/images/profileIcon.svg';
import { schema, initialValues } from './schema';
import ImageInput from "Components/Common/ImageInput";

const FormContainer = ({ }) => {
    const inputRef = React.useRef(null);
    const handleClick = (ref) => {
        ref.current.click();
    };
    return (
        <>
            <Formik
                initialValues={initialValues()}
                validationSchema={schema}
                validate={values => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FileInput accept=".csv" label={"Import Buildings from File (CSV)"} linkText={"Download CSV Template"} icon={PaperClip} name='file' value={values.file} onChange={handleChange} error={touched.file && errors.file} onBlur={handleBlur} />
                        <Divider />
                        <div className={styles.profileIconWrapper}>
                            <ImageInput accept="image/*" type="file" icon={profileIcon} inputRef={inputRef} handleClick={handleClick} id='profilePicture' name="profilePicture" value={values.profilePicture} onChange={(event) => setFieldValue('profilePicture', [event.currentTarget.files[0]])} error={touched.profilePicture && errors.profilePicture} onBlur={handleBlur} profileIcon={true} inputWrapperStyle={styles.inputWrapperStyle} inputStyle={styles.inputStyle} />
                        </div>
                        <div className={styles.columns}>
                            <div className={styles.leftColumn}>
                                <Input name="fullName" value={values.fullName} onChange={handleChange} onBlur={handleBlur} error={touched.fullName && errors.fullName} label={'Full Name'} placeholder='The Dylan' />
                                <Input name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} error={touched.phone && errors.phone} label={'Phone'} placeholder='Type In...' />
                                <Input name="address" value={values.address} onChange={handleChange} onBlur={handleBlur} error={touched.address && errors.address} label={'Address'} placeholder='Type In...' />
                                <Input name="snn" value={values.snn} onChange={handleChange} onBlur={handleBlur} error={touched.snn && errors.snn} label={'SNN'} placeholder='Type In...' />
                                <Input name="passport" value={values.passport} onChange={handleChange} onBlur={handleBlur} error={touched.passport && errors.passport} label={'Passport'} placeholder='Type In...' />
                                <Input name="emergencyContact" value={values.emergencyContact} onChange={handleChange} onBlur={handleBlur} error={touched.emergencyContact && errors.emergencyContact} label={'Emergency Contact'} placeholder='Type In...' />
                            </div>
                            <div className={styles.rightColumn}>
                                <Input name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur} error={touched.lastName && errors.lastName} label={'Last Name'} placeholder='The Dylan' />
                                <Input name="birthday" value={values.birthday} onChange={handleChange} onBlur={handleBlur} error={touched.birthday && errors.birthday} label={'Birthday'} placeholder='Type In...' />
                                <Input name="previousAddress" value={values.previousAddress} onChange={handleChange} onBlur={handleBlur} error={touched.previousAddress && errors.previousAddress} label={'Previous Address'} placeholder='Type In...' />
                                <Input name="driverLicense" value={values.driverLicense} onChange={handleChange} onBlur={handleBlur} error={touched.driverLicense && errors.driverLicense} label={'Driver License'} placeholder='Type In...' />
                                <Input name="occupation" value={values.occupation} onChange={handleChange} onBlur={handleBlur} error={touched.occupation && errors.occupation} label={'Occupation'} placeholder='Type In...' />
                                <Input name="emergencyContactPhone" value={values.emergencyContactPhone} onChange={handleChange} onBlur={handleBlur} error={touched.emergencyContactPhone && errors.emergencyContactPhone} label={'Emergency Contact Phone'} placeholder='Type In...' />

                            </div>
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <button className={styles.button}>Save & Add Another</button>
                            <button className={styles.button}>Save</button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}
export default FormContainer;