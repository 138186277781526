import React, { useState } from 'react';
import styles from './styles.module.scss';
import divider from 'assets/images/divider.svg';
import buildings from 'assets/images/featuresBuildingBackground.png';
import NavigationTitles from './NavigationTitles';
import FeaturesGrid from './FeaturesGrid';
import NavigationDots from './NavigationDots';
import classes from 'classnames';

const FeaturesSection = () => {
   const [isManagers, setIsManagers] = useState(true);
   const toggleTabs = (isManagersClicked) => {
      if (isManagersClicked !== isManagers) {
         setIsManagers(!isManagers);
      }
   }
   return (
      <div className={styles.root}>
         <section id="features" className={styles.section}>
            <span className={styles.header}>Features </span>
            <img src={divider} className={styles.divider} alt="" />
            <div className={styles.navigation}>
               <NavigationTitles leftText='Managers & Finances' rightText='Tenants & Leasing' toggleTabs={toggleTabs} isManagers={isManagers} />
            </div>
            <div className={classes(styles.managersContainer, {[styles.hidden]: !isManagers })}>
               <img className={styles.buildings} src={buildings} />
               <FeaturesGrid isManagers />
            </div>
            <div className={classes(styles.tenantsContainer, {
               [styles.slideOut]: isManagers,
               [styles.slideIn]: !isManagers,
               [styles.secondTabsActive]: !isManagers
            })}>
               <img className={styles.buildings} src={buildings} />
               <FeaturesGrid isManagers={false} />
            </div>
            <NavigationDots isManagers={isManagers} />
         </section>
      </div>);
};

export default FeaturesSection;