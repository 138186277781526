import React from 'react';
import Modal from 'Components/Common/Modal';

const TenantLoginVerificationFirstStep = ({ ...props }) => {
    return (
        <Modal
            title='Enter Your EMAIL'
            content='Check your email for your temporary password.'
            buttonText='Submit'
            inputs={[{ placeholder: 'Email', type: 'text' }]}
            {...props}
        />
    )
};
export default TenantLoginVerificationFirstStep;