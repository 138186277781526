import { IS_SUCCESS } from "../../Redux/consts";
import { fromJS } from "immutable"
import { SET_REGISTER_USER_SUCCESS } from "./consts";


const initialState = fromJS({
        [IS_SUCCESS]: false
});


const reducer = (state, action) => {
    switch (action.type) {
        case SET_REGISTER_USER_SUCCESS:
            return state.set(IS_SUCCESS, action.isSuccess)
        default: 
            return initialState
    }
}

export default reducer