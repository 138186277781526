import React from 'react';
import styles from './styles.module.scss';
import divider from 'assets/images/whiteDivider.svg';
import ContactDetail from './ContactDetail';
import emailIcon from 'assets/images/emailIconSmall.svg';
import phoneIcon from 'assets/images/phoneIconSmall.svg';
import clockIcon from 'assets/images/clockIconSmall.svg';
import locationIcon from 'assets/images/locationIconSmall.svg';
import BottomBar from '../BottomBar';

const microcopy = {
   hours: `Mon - Fri
   9.00 AM - 6.00 PM`,
   location: `519 E 5th St
    Suite 17
    New York NY 
    10009`
}

const ContactUs = () => {
   return (
      <div className={styles.root}>
         <section id="contactUs" className={styles.columns}>
            <div className={styles.leftColumn}>
               <span className={styles.header}>Say HI!</span>
               <img src={divider} className={styles.divider} alt="" />
               <span className={styles.description}>Feel free to contact us:</span>
               <ContactDetail icon={emailIcon} header='Email' detail='contact@tigra.app' detailType='mailto' />
               <ContactDetail icon={phoneIcon} header='Phone' detail='917-943-5222' detailType='tel' />
               <ContactDetail icon={clockIcon} header='Hours' detail={microcopy.hours} />
               <ContactDetail icon={locationIcon} header='Location' detail={microcopy.location} />
            </div>
            <div className={styles.rightColumn}>
               <input placeholder={'Full Name*'} className={styles.input} />
               <input placeholder={'Email*'} className={styles.input} />
               <textarea placeholder={'Message*'} className={styles.textArea} />
               <button className={styles.button} > Submit </button>
            </div>
         </section>
         <BottomBar />
      </div>);
};

export default ContactUs;