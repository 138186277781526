import { call, put, takeEvery} from 'redux-saga/effects'
import { loadUnit, submitUnitApplication } from '../../api/properties';
import { setIsSuccess } from '../../Redux/actions';
import { onFailure } from '../Toastr/actions';
import { formatUnitApplicationData, formatUnitApplicationTenantData } from '../UnitApplicationForm/helper';
import { loadUnitSuccess } from './actions';
import { LOAD_UNIT, SUBMIT_TENANT_UNIT_APPLICATION, TENANT_APPLICATION_SUBMISSION_ } from './consts';

function* loadUnitSaga({id}){
    try {
        const res = yield call(loadUnit, id);
        yield put(loadUnitSuccess(res.unit));
    } catch(e) {
        console.log(e)
        yield put (onFailure("Failed to find unit."))
    }
};

function* submitTenantUnitApplicationSaga({data}){
    try {
        const tenantData = formatUnitApplicationTenantData(data)
        const applicationData = formatUnitApplicationData(data)
        const res  = yield call(submitUnitApplication, {tenantData, applicationData})
        if (res?.unitApplicationTenant?.success) yield put(setIsSuccess(true, TENANT_APPLICATION_SUBMISSION_))
    } catch(e) {
        yield put(setIsSuccess(false, TENANT_APPLICATION_SUBMISSION_))
        yield put (onFailure("Failed to submit unit application."))
    }
};

export default function* defaultSaga() {
    yield takeEvery(LOAD_UNIT, loadUnitSaga);
    yield takeEvery(SUBMIT_TENANT_UNIT_APPLICATION, submitTenantUnitApplicationSaga);
}