import React from 'react';
import styles from './styles.module.scss';
import FormContainer from "./FormContainer";

const ManagementCompanyUsersTab = () => {
   return <div className={styles.root}>
      <FormContainer />
   </div>
};

export default ManagementCompanyUsersTab;
