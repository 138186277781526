import React from 'react';
import styles from './styles.module.scss';
import logo from 'assets/images/TigraWordmarkLogomark.png';
import whiteLine from 'assets/images/whiteLine.svg';
import orangeLine from 'assets/images/orangeLine.svg';
import greyDivider from 'assets/images/greyDivider.svg';
import instagram from 'assets/images/instagramIcon.svg';
import facebook from 'assets/images/facebook.svg';
import youtube from 'assets/images/youtube.svg';
import { NavLink } from 'react-router-dom';
import classes from 'classnames';

const BottomBar = ({ withBackground = false }) => {
    return (
        <div className={classes({
            [styles.noBackground]: !withBackground,
            [styles.withBackground]: withBackground
        })}>
            <div className={styles.container}>
                <div className={styles.upBulk}>
                    <img src={logo} className={styles.logo} alt="" />
                    <div className={styles.navigationColumns}>
                        <div className={styles.column}>
                            <img src={whiteLine} className={styles.line} alt="" />
                            <span className={styles.header}>Product</span>
                            <a href="#about" className={styles.option}>Solutions</a>
                            <a href="#features" className={styles.option}>Features</a>
                            <a href="#plans" className={styles.option}>Pricing</a>
                        </div>
                        <div className={styles.column}>
                            <img src={orangeLine} className={styles.line} alt="" />
                            <span className={styles.header}>Company</span>
                            <a href="#about" className={styles.option}>About us</a>
                            <a href="#contactUs" className={styles.option}>Contacts</a>
                        </div>
                        <div className={styles.column}>
                            <img src={whiteLine} className={styles.line} alt="" />
                            <span className={styles.header}>Legal</span>
                            <NavLink className={styles.option} to='/termsOfService'>Terms of Service</NavLink>
                            <NavLink className={styles.option} to='/privacyPolicy'>Privacy policy</NavLink>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomLineDivider}>
                    <div className={styles.greyDivider}></div>
                    <div className={styles.bottomLineContent}>
                        <span className={styles.copyright}>Copyright ©TIGRA 2020. All rights Reserved</span>
                        <div className={styles.followUsLinks}>
                            <NavLink to="#" className={styles.followUs}>Follow Us</NavLink>
                            <div className={styles.followIconsBox}>
                                <NavLink to="#"><img src={facebook} className={styles.icon} alt="" /> </NavLink>
                                <NavLink to="#"><img src={instagram} className={styles.icon} alt="" /></NavLink>
                                <NavLink to="#"><img src={youtube} className={styles.icon} alt="" /></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >);
};

export default BottomBar;