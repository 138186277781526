import React from 'react';
import styles from './styles.module.scss';
import classes from 'classnames';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const Input = ({ value, label, instruction, error, handleClick, inputRef, icon, type = 'text', accept, onChange, bottomLine, startAdornment, endAdornment, inputWrapperStyle, inputStyle, ...props }) => {
    return (
        <>
            <div className={classes(styles.inputWrapper, inputWrapperStyle)} onClick={() => handleClick && handleClick(inputRef)}>
                {label && <label className={styles.label}>{label}{instruction && <span className={styles.instruction}>{instruction}</span>}</label>}
                {icon && <img src={icon} />}
                {(type == 'text' || type === "tel") ? <TextField
                    id="input-with-icon-textfield"
                    InputProps={{
                        startAdornment: startAdornment && (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ),
                        endAdornment: endAdornment && (
                            <InputAdornment position="end">
                                {endAdornment}
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    type={type}
                    inputRef={inputRef}
                    className={classes({ [inputStyle]: !!inputStyle, [styles.input]: true })}
                    {...props}
                /> :
                    <input type={type} accept={accept} onChange={onChange} ref={inputRef} value={value} className={classes({ [inputStyle]: !!inputStyle, [styles.input]: true })} {...props} />}
                {bottomLine && <span className={styles.bottomLine}>{bottomLine}</span>}
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </>
    )
}
export default Input;