import React from 'react';
import styles from './styles.module.scss';
import divider from 'assets/images/whiteDivider.svg';
import servicesIcon from 'assets/images/servicesIcon.png';
import leaseIcon from 'assets/images/leaseIcon.svg';
import apartmentIcon from 'assets/images/apartmentIcon.svg';
import noticesIcon from 'assets/images/noticesIcon.svg';
import iphone from 'assets/images/iphoneTigra.png'
import vector1 from 'assets/images/vector1.svg';
import vector2 from 'assets/images/vector2.svg';
import vector3 from 'assets/images/vector3.svg';
import vector4 from 'assets/images/vector4.svg'
import Paragraph from './Paragraph';

const LeaseManagementSection = () => {
   return (
      <div className={styles.root}>
         <span className={styles.header}>Lease Management</span>
         <img src={divider} className={styles.divider} alt="" />
         <div className={styles.columns}>
            <div className={styles.leftColumn}>
               <Paragraph icon={servicesIcon} subtitle="Service Providers" text="Owners can choose between their personal service providers & our service providers to ensure the job gets done on time. " />
               <div className={styles.vectorWrapper}>
                  <img src={vector1} className={styles.vector} alt="" />
               </div>
               <div className={styles.vectorWrapper}>
                  <img src={vector2} className={styles.vector} alt="" />
               </div>
               <Paragraph icon={apartmentIcon} subtitle="Apartment Rentals" text="Owners can upload & market all vacant apartments on the open market." />
            </div>
            <div className={styles.centerColumn}>
               <img src={iphone} className={styles.iphone} alt="" />
            </div>
            <div className={styles.rightColumn}>
               <Paragraph icon={leaseIcon} subtitle="Lease & Riders" text="TIGRA provides lease agreements, renewal lease forms & all necessary riders by law (as well as any additional riders landlords require for their protection). Landlords can customize & add their logos. " rightMargin={false} />
               <div className={styles.vectorWrapper}>
                  <img src={vector3} className={styles.vector} alt="" />
               </div>
               <div className={styles.vectorWrapper}>
                  <img src={vector4} className={styles.vector} alt="" />
               </div>
               <Paragraph icon={noticesIcon} subtitle="Notices" text="Users can list a notice for any situation that arises in property rental. Each notice form can be customized & with the user’s company logo." rightMargin={false} />
            </div>
         </div>
      </div>);
};

export default LeaseManagementSection;