import React from 'react';
import Paragraph from '../index';
import phoneGif from 'assets/images/phone.gif';
import styles from "./styles.module.scss";

const microcopy = {
    title: 'Imagine a digital utopia  where everything just works',
    mainText: 'Your daily property management operations, supply chains, financials, maintenance, legal services & business partners work in unison. You can track the progress of all work in real-time, with total clarity. Function & communication are as laser-sharp as the eye of a tiger.',
    bottomText: `That world is here,
    Welcome to Tigra!`,
}

const PhoneParagraph = () => (
    <Paragraph
        gif={phoneGif}
        title={microcopy.title}
        mainText={microcopy.mainText}
        bottomText={microcopy.bottomText}
        className={styles.container}
    />
)


export default PhoneParagraph;