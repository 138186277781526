import React from 'react';
import styles from '../styles.module.scss';
import Document from '../Document';
import MainLayout from 'Components/Layout/MainLayout';
import items  from './items';
import { useSearchParams } from 'hooks/useSearchParams';

const firstParagraph = `TIGRA is committed to providing clear and concise information regarding its privacy policies and practices.  This online privacy policy ("Privacy Policy") describes how TIGRA INC, its affiliates and subsidiaries (collectively, "TIGRA") collect, use, and disclose certain personal information in the course of providing products and services through TIGRA' Site, App, products, and services (collectively, "Goods and Services").  By accessing a TIGRA Site or App, or by utilizing a TIGRA products or services, you consent to the information collection, use, and handling practices outlined in this this Privacy Policy.`;

const PrivacyPolicy = () => {
    const {value:mode} =useSearchParams('mode');
    const webview_content_only = mode == 'webview_content_only';
    return webview_content_only?
        <div className={styles.root} style={{paddingRight:'1em'}}>
            <div className={styles.wideWrap}>
                <Document
                    data={items}
                    firstParagraph={firstParagraph}
                />
            </div>
        </div>
        :
        <MainLayout>
            <div className={styles.root}>
                <div className={styles.wrap}>
                    <Document
                        links={[{ displayName: 'Home', url: '/' }]}
                        header='Privacy Policy'
                        data={items}
                        firstParagraph={firstParagraph}
                    />
                </div>
            </div>
        </MainLayout>
};

export default PrivacyPolicy;