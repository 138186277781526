import crossCircle from "../../../assets/images/crossCircle.svg";
import React from 'react';
import styles from './styles.module.scss';

const SelectedValues = ({ value }) => {
    return (
        <div className={styles.listContainer}>
            <>
                {!!value.length && value?.map((val) => <>
                    <span className={styles.selectedList}>{val}</span>
                    <i className={styles.icon} onClick={() => { }}>
                        <img src={crossCircle} />
                    </i>
                </>)}
            </>
        </div>
    )
};

export default SelectedValues;