import React from 'react';
import Heading from 'Components/Common/Heading';

import MultiPartFormContainer from 'Components/MultiPartContainer';
import { useGetInputProps } from 'hooks/useGetInputProps';
import Input from 'Components/Common/Input';
import styles from "../styles.module.scss";
import { guarantorNote } from '..';
import { BuildingAddress } from '../UnitApplicationFormAttachments';
import AmountInput from '../../../Common/Form/Fields/AmountInput';
import PhoneInput from '../../../Common/Form/Fields/PhoneInput';

const UnitApplicationFormResidenceHistory = ({ onNext, onPrev, setValue, values, heading, headingPrepender, unitAddress }) => {
    const getInputProps = useGetInputProps({values, setValue,inputWrapperStyle: styles.input});
    return (
        <MultiPartFormContainer
            containerClass={styles.formSectionContainer}
            onNext={onNext}
            onPrev={onPrev}
            headingPrepender={headingPrepender}
            heading={heading}
        >   
            <div className={styles.divSeparator}>
                <BuildingAddress address={unitAddress} />
                <Heading className={styles.sectionSubheading} text={"Residence History"} />
                <Input label={"Current address"} {...getInputProps("currentAddress")} />
                <Input label={"How long have you lived at this address?"} {...getInputProps("currentAddressDuration")} />
                <Input label={"Current Landlord"} {...getInputProps("currentLandlord")} />
                <PhoneInput label={"Phone Number "} {...getInputProps("landlordPhone")} />
                <AmountInput label={"Current rent "} {...getInputProps("currentRent")} />
                <Input label={"Reasons for moving"} {...getInputProps("reasonForMoving")} />
            </div>
            {guarantorNote}
        </MultiPartFormContainer>
    )   
};

export default UnitApplicationFormResidenceHistory