
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
  
export default function ScrollTop() {
  const routePath = useLocation();

  const onTop = () => {
    const top = routePath?.hash?document.getElementById(routePath.hash.substring(1))?.offsetTop:0;
    window.scrollTo({top, behavior: 'smooth'});
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  
  return null;
}