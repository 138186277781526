import React from "react"
import * as Sections  from "./sections"
export default  [
    {header:"Acceptance of Terms",content:(<Sections.AcceptanceOfTerms/>)},
    {header:"Changes to TOS",content:(<Sections.ChangeToTos/>)},
    {header:"Eligibility",content:(<Sections.Eligibility/>)},
    {header:"Reliance on Information Posted",content:(<Sections.RelianceOnInfo/>)},
    {header:"Access and User Account",content:(<Sections.AccessAndUserAccount/>)},
    {header:"Your Content",content:(<Sections.YourContent/>)},
    {header:"Tigra Inc's  Use of Your Content",content:(<Sections.UseOfYourContent/>)},
    {header:"Removal of Content",content:(<Sections.RemovalOfContent/>)},
    {header:"Online Conduct",content:(<Sections.OnlineConduct/>)},
    {header:"Termination/Suspension",content:(<Sections.Termination/>)},
    {header:"Unlawful Use",content:(<Sections.UnlawfulUse/>)},
    {header:"Links and Vendors",content:(<Sections.LinksAndVendors/>)},
    {header:"Unsolicited Ideas or Suggestions",content:(<Sections.UnsolicitedIdeas/>)},
    {header:"Disclaimer",content:(<Sections.Disclaimer/>)},
    {header:"Limitations of Liabilities",content:(<Sections.LimitationsOfLiabilities/>)},
    {header:"Indemnification",content:(<Sections.Indemnification/>)},
    {header:"Limitations on Time to File Claims",content:(<Sections.LimitationsOnTimeToFileClaims/>)},
    {header:"Intellectual Property Rights",content:(<Sections.IntellectualPropertyRights/>)},
    {header:"Copyrights",content:(<Sections.Copyrights/>)},
    {header:"Trademarks",content:(<Sections.Trademarks/>)},
    {header:"Jurisdiction - Forum Selection",content:(<Sections.ForumSelection/>)},
    {header:"Successors and Assigns; Third Party Beneficiaries  ",content:(<Sections.ThirdPartyBeneficiaries/>)},
    {header:"Disclosures Required by Law and Other Instances",content:(<Sections.Disclosure/>)},
    {header:"Tracking",content:(<Sections.Tracking/>)},
    {header:"Public Contributions",content:(<Sections.PublicContributions/>)},
    {header:"Contests and Promotions",content:(<Sections.ContestsAndPromotions/>)},
    {header:"Investigations",content:(<Sections.Investigations/>)},
    {header:"Terms of Sale/Purchase of Products or Services",content:(<Sections.TermsOfSalePurchase/>)},
    {header:"Payment Terms",content:(<Sections.PaymentTerms/>)},
    {header:"Monitoring",content:(<Sections.Monitoring/>)},
    {header:"Entire Agreement",content:(<Sections.EntireAgreement/>)},
    {header:"Modification and Waiver",content:(<Sections.ModificationAndWaiver/>)},
    {header:"Unenforceable Provisions",content:(<Sections.UnenforceableProvisions/>)},
    {header:"Contact Information",content:(<Sections.ContactInformation/>)}
]