import React from 'react';
import styles from './styles.module.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const CollapsibleHeader = ({ header, open }) => {
    return <div className={styles.root}>
        <p className={styles.text}> {header} </p>
        {open? <RemoveIcon className= {styles.toggler} /> : <AddIcon  className= {styles.toggler} />}
    </div >;
};

export default CollapsibleHeader;
