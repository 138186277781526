import React from 'react';
import styles from './styles.module.scss';
import classes from 'classnames';

const Paragraph = ({ icon, subtitle, text, rightMargin = true, style }) => {
   return (
      <div
         className={classes(styles.root, {
            [styles.withRightMargin]: rightMargin,
            [styles.withLeftMargin]: !rightMargin
         })}
         style={style}>
         <img src={icon} className={styles.icon} alt="" />
         <span className={styles.subtitle}>{subtitle}</span>
         <span className={styles.text}>{text} </span>
      </div>);
};

export default Paragraph;