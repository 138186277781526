import * as yup from 'yup';

export const initialValues = () => {
    return {
        file: null,
        profilePicture: [],
        fullName: "",
        lastName: "",
        phone: "",
        birthday: "",
        address: "",
        previousAddress: "",
        snn: "",
        passport: "",
        emergencyContact: "",
        driverLicense: "",
        occupation: "",
        emergencyContactPhone: ""
    }
};

export const schema = yup.object().shape({
    file: yup.mixed()
        .nullable()
        .required('A file is required'),
    fullName: yup
        .string()
        .max(200)
        .required('Full name is required')
        .label('Full Name'),
    lastName: yup
        .string()
        .max(200)
        .required('Last name is required')
        .label('Last Name'),
    phone: yup
        .number()
        .required('Enter valid Phone Number')
        .label('Phone'),
    birthday: yup
        .string()
        .max(30)
        .required('Birthday is required')
        .label('Birthday'),
    address: yup
        .string()
        .max(50)
        .required('Address is required')
        .label('Address'),
    previousAddress: yup
        .string()
        .max(50)
        .required('Previous Address is required')
        .label('Previous Address'),
    snn: yup
        .number()
        .required('SNN is required')
        .label('SNN'),
    passport: yup
        .string()
        .max(100)
        .required('Passport is required')
        .label('Passport'),
    emergencyContact: yup
        .string()
        .max(100)
        .required('Emergency Contact is required')
        .label('Emergency Contact'),
    driverLicense: yup
        .string()
        .max(100)
        .required('Driver License is required')
        .label('Driver License'),
    occupation: yup
        .string()
        .max(100)
        .required('Occupation is required')
        .label('Occupation'),
    emergencyContactPhone: yup
        .number()
        .required('Emergency Contact Phone is required')
        .label('Emergency Contact Phone'),
});