import React from 'react';
import Modal from 'Components/Common/Modal';

const TemporaryPasswordModal = ({ close, ...props }) => {
    return (
        <Modal
            title='Enter Your Temporary Password'
            buttonText='Submit'
            transparent
            close={close}
            {...props} />
    )
};

export default TemporaryPasswordModal;