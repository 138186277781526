import React from 'react';
import styles from './styles.module.scss';
import MenuLink from '../MenuLink';

const Desktop = ({ withBackground, menus }) => {
    const rootStyle = withBackground ? styles.withBackground : null;
    return <div className={rootStyle}>
        <div className={styles.links}>
            {
                menus.map((menu, index) => <MenuLink key={index} menu={menu} styles={styles} />)
            }
        </div>
    </div >;
};


export default Desktop;