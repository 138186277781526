import React from 'react';
import classes from 'classnames';
import styles from './styles.module.scss';
import Feature from './Feature';
import icon1 from 'assets/images/feature1.png';
import icon2 from 'assets/images/feature2.png';
import icon3 from 'assets/images/feature3.png';
import icon4 from 'assets/images/feature4.png';
import icon5 from 'assets/images/feature5.png';
import icon6 from 'assets/images/feature6.png';
import icon7 from 'assets/images/feature7.png';
import icon8 from 'assets/images/feature8.svg';
import icon10 from 'assets/images/feature10.png';
import icon11 from 'assets/images/feature11.png';
import icon12 from 'assets/images/feature12.png';

const FeaturesGrid = ({ isManagers }) => {
   return (
      <div className={styles.root}>
         {isManagers ?
            [<div className={styles.row}>
               <Feature title='Properties' icon={icon7} content='This interactive properties portal uses tenant information to automatically prompt you to either fill in the lease renewal form or list the unit on the open market, as well as view and manage units & buildings.' />
               <Feature title='Task Manager' icon={icon1} content='Track of all your upcoming events, meetings, alerts & work orders with to-do lists that are customized for each team member. Beyond just reminders, you will be prompted to act so that everything gets done on time.' />
               <Feature title='Fillable Forms & Contracts:' icon={icon2} content='Any file, form, or contract you need is stored in this digital file cabinet, including leases, riders, notices & legal documents. The system automatically customizes each form & fills it based on the information you’ve inputted. ' />
            </div>,
            <div className={styles.row}>
               <Feature title='Accounting Dashboard:' icon={icon4} content='Your accounting payable & receivable, ledgers & daily reports are maintained for you for maximum operational efficiency.' />
               <Feature title='Service Providers' icon={icon3} content='The services portal provides an extensive list of service providers by location, availability & rating. Management can upload their preferred providers, communicate with providers & receive status updates on jobs. ' />
               <Feature title='E-files' icon={icon6} content='A digitized, systemized space for all relevant documents, including leases & contracts.' />
            </div>]
            :
            [<div className={classes(styles.row, styles.FeatureSecondTab)}>
               <Feature title='Tenant Portal' icon={icon8} content='A one-stop-shop for tenants to access their leases & make secure online rent payments. This portal provides centralized messaging between building management & tenants. ' />
               <Feature title='Maintenance Requests' icon={icon3} content='Tenants can make maintenance requests with photos & videos for easy review. The system tracks all service requests, the status of the job & the cost.' />
               <Feature title='Payment System' icon={icon5} content='Tenants can securely pay rent online. Managers can pay invoices to service providers.' />
            </div>,
            <div className={styles.row}>
               <Feature title='Tenant Screening' icon={icon10} content='We offer the choice of a basic to comprehensive background check summary to ensure you are getting the applicant you want.' />
               <Feature title='Tenant Rating' icon={icon11} content="Our algorithm displays a tenant' s complete profile, including on-time rent payments, complaint history, and more & rates them accordingly so you can be sure to get the best tenants." />
               <Feature title='Compliance' icon={icon12} content='The system keeps track of building complaints & violations, reminds you of key dates & legal notices, and prompts you to take action on outdated service requests.' />
            </div>]
         }
      </div >);
};

export default FeaturesGrid;
