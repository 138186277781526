import React from 'react';
import Modal from 'Components/Common/Modal';

const SigninVarificationFirstStep = ({ ...props }) => {
    return (
        <Modal
            title='Enter Phone Number'
            content='2-factor authentication enables us to ensure that your account is secure. Please enter your phone number below to receive a verification code.'
            buttonText='Submit'
            inputs={[{ placeholder: '000-000-0000', type: 'text' }]}
            {...props}
        />
    )
};
export default SigninVarificationFirstStep;