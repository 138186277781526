import React from 'react';
import styles from './styles.module.scss'

const RenderValue = ({text, iconLeft, actionIconRightdeleteIcon, onRemove}) =>{
    return(
        <div className={styles.renderValue} >
            <div className={styles.iconContainer}>
                <img src={iconLeft}/>
            </div>
            <div className={styles.fileNameContainer}>
                <label>{text}</label>
            </div>
            <div className={styles.deleteBtnContainer}>
                <img onClick={onRemove} src={actionIconRightdeleteIcon}/>
            </div>
        </div>
    )
}

export default RenderValue;
