import {call, put, takeEvery} from 'redux-saga/effects'
import { registerUser } from '../../api/auth';
import { onFailure } from '../Toastr/actions';
import { setRegisterUserSuccess } from './actions';
import { REGISTER_USER } from './consts';


function* registerUserSaga({data}){
    try {
        const res = yield call(registerUser,data)
        yield put (setRegisterUserSuccess(res?.registerUser?.success))
    } catch(e) {
        yield put (onFailure("Failed to register user."))
    }


}

export default function* defaultSaga() {
    yield takeEvery(REGISTER_USER, registerUserSaga);
}