import React from 'react';
import styles from './styles.module.scss';
import grayDot from 'assets/images/Accent1Dot.svg';
import darkDot from 'assets/images/secondary2Dot.svg';
import classes from 'classnames';

const NavigationDots = ({ isManagers = true }) => {
   const leftDotImage = isManagers ? darkDot : grayDot;
   const rightDotImage = isManagers ? grayDot : darkDot;

   return (
      <div className={styles.navigationDots}>
         <img src={leftDotImage} className={classes(styles.leftDot, {
            [styles.activeDot]: isManagers,
            [styles.inactiveDot]: !isManagers
         })} alt="" />
         <img src={rightDotImage} className={classes(styles.rightDot, {
            [styles.activeDot]: !isManagers,
            [styles.inactiveDot]: isManagers
         })} alt="" />
      </div>);
};


export default NavigationDots;