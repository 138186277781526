import React from 'react';
import styles from './styles.module.scss';
import Backdrop from '../Backdrop';
import x from '../../../assets/images/whiteX.svg';

const Modal = ({ title, content, buttonText, inputs = [], close, clicked, onClick = clicked, transparent = false, link, ...props }) => {
    return (
        <div className={styles.root}>
            <Backdrop close={close} transparent={transparent} />,
            <div className={styles.root}>
                <img onClick={close} src={x} className={styles.x} alt="" />
                <div className={styles.rootContent}>
                    <span className={styles.title}> {title} </span>
                    {content && <span className={styles.content} >{content} </span>}
                    {inputs.map((input, i) => <div key={i} className={styles.InputWrapper}> <input className={styles.smallInput} {...input} small={true} /></div>)}
                    <button className={styles.button} onClick={onClick} >{buttonText}</button>
                    {link && <span className={styles.link}> {link.regularText} <span> {link.greyText} </span></span>}
                </div>
            </div>
        </div>
    )
};
export default Modal;