import React from 'react';
import AttachmentField from 'Components/Common/Form/Fields/Attachmentfield';
import Heading from 'Components/Common/Heading';
import styles from "../styles.module.scss"

const GuarantorFields = ({ values, setValue }) => {
    return <>
        <Heading text={"Guarantor Documents"} Tag="h4" />
        <p className={styles.subDescription}>Guarantor but make at least 80X the rent or greater and have excellent credit. They must live in the U.S.</p>
        <p className={styles.subDescription}>All documents can be in format JPG, PNG, PDF.</p>
        <AttachmentField label={"Rental application"} setValue={(files) => setValue("rentalApplication", files)} value={values?.["rentalApplication"]} multiple />
        <AttachmentField label={"Most recent federal tax return, W-2 or letter from CPA"} setValue={(files) => setValue("guarantorCPALetter", files)} value={values?.["guarantorCPALetter"]} multiple />
        <AttachmentField label={"Signed and notarized guarantor form"} setValue={(files) => setValue("guarantorForm", files)} value={values?.["guarantorForm"]} multiple />
        <AttachmentField label={"Credit report"} setValue={(files) => setValue("guarantorCreditReport", files)} value={values?.["guarantorCreditReport"]} multiple />
        <AttachmentField label={"Photo ID"} setValue={(files) => setValue("guarantorPhotoID", files)} value={values?.["guarantorPhotoID"]} multiple />
    </>
}

export default GuarantorFields;