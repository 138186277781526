import React from 'react';
import styles from './styles.module.scss';
import divider from 'assets/images/divider.svg'
import classes from 'classnames';

const Paragraph = ({ title, bottomText, gif, mainText, bottomTextBold = "", leftToRight = true, className }) => {
    return <div className={classes({
        [styles.root]: leftToRight,
        [styles.reserveFlowRoot]: !leftToRight,
        [className]: !!className
    })}>
        <div className={styles.gifBulk}>
            <img src={gif} className={styles.gif} alt="" />
        </div>
        <div className={styles.textBulk}>
            <span className={styles.title}>{title}</span>
            <img src={divider} className={styles.divider} alt="" />
            {mainText && <span className={styles.mainText}> {mainText}</span>}
            <span className={classes({
                [styles.bottomText]: bottomText
            })}>{bottomText}</span>
            {bottomTextBold && <span className={classes({
                [styles.bottomTextBold]: bottomTextBold,
                [styles.bottomText]: !bottomTextBold
            })}>{bottomTextBold}</span>}
        </div>
    </div>;
};

export default Paragraph;