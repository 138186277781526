import React from 'react';
import styles from './styles.module.scss';
import classes from 'classnames';

const Wizard = ({ steps, currentStep, stepChange }) => {
   return <div className={styles.root}>
      <div className={styles.steps}>
         {steps.map(step => {
            return (
               <div className={styles.stepWrapper} key={step.number} onClick={() => stepChange(step.number)}>
                  <div className={currentStep === step.number ? classes(styles.stepCircle, styles.chosenStep) : styles.stepCircle}><p className={styles.number}>{step.number}</p></div>
                  <p className={styles.stepDescription}>{step.description} </p>
               </div>
            )
         }
         )}
      </div>
   </div>;
};

export default Wizard;
