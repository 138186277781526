export function getIn(obj, arr, defaultValue = null) {
    try {
        let result = obj;
        for (let key of arr) {
            result = result[key];
        }
        return result;
    }
    catch (e) {
        return defaultValue;
    }
};

export function setOption(arr, objectKey) {
    const options = arr.map(item => ({
        "value": item[objectKey].name,
        "label": item[objectKey].name
    }));
    return options;
}