import {createSelector} from "reselect";

export function safeSelector(...functions) {
    const [callback] = functions.splice(-1);
    return createSelector(
        ...functions,
        (...args) => {
            try {
                return callback(...args)
            }
            catch (e) {
                return undefined;
            }
        }
    )
}
