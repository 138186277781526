import { LOAD_UNIT, LOAD_UNIT_SUCCESS, SUBMIT_TENANT_UNIT_APPLICATION } from "./consts";

export const loadUnit = (id) => ({
    type: LOAD_UNIT,
    id
});

export const loadUnitSuccess = (data) => ({
    type: LOAD_UNIT_SUCCESS, 
    data
})

export const submitTenantUnitApplication = (data) => ({
    type: SUBMIT_TENANT_UNIT_APPLICATION,
    data
})
