import React from 'react';
import Paragraph from '../index';
import puzzleGif from 'assets/images/puzzle.gif';
import styles from "./styles.module.scss"

const PuzzleParagraph = () => (
    <Paragraph 
        gif={puzzleGif} 
        title='Ensure your team gets their jobs done.' 
        bottomText='Automate the process.' 
        className={styles.container}
    />
)

export default PuzzleParagraph;