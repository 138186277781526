import React from 'react';
import { Provider } from "react-redux";
import store from "./Redux/store";
import Routes from "./Routes";
import StyleLoader from "./Components/StylesLoader";
import "./App.module.scss";
import Toastr from './Components/Toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyleLoader />
        <Toastr />
        <Routes />
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
