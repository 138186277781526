import { useLocation } from 'react-router-dom';

export const useSearchParams = (name = '') => {

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const value = params.get(name);

    return {
        params,
        value
    }
}