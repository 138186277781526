import React from 'react';
import NavBar from 'Components/pages/LandingPage/NavBar';

const SiteHeader = () => {
    return (
        <div>
            <NavBar withBackground/>
        </div>
    );
};


export default SiteHeader;