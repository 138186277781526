import React from 'react';
import styles from './styles.module.scss';
import greyDivider from 'assets/images/accent1Divider.svg';
import classes from 'classnames';

const Plan = ({ planName, price, smallPriceText = "", conditions, isActive = false }) => {
   return (

      <div className={classes(styles.root, {[styles.activeSlick]: isActive,})}>
         <span className={styles.header}><b>{planName}</b> PLAN</span>
         {/* <img src={greyDivider} className={styles.divider} alt="" />
         <span className={styles.price}> {price} <span className={styles.smallPriceText}> {smallPriceText}</span></span>
         <span className={styles.priceNote}>For all period</span>
         <div className={styles.conditions}>
            {conditions.map(condition =>
               <div className={styles.line}>
                  <span className={styles.conditionAmount}>{condition.amount}</span>
                  <span className={styles.conditionContent}>{condition.content}</span>
               </div>
            )}
         </div>
         <button className={styles.button}> Select Plan </button> */}
      </div>);
};

export default Plan;