import React from "react"
import * as Sections  from "./sections"
export default  [
    {header:"Definitions",content:(<Sections.Definitions/>)},
    {header:"This Privacy Policy in General",content:(<Sections.PpInGeneral/>)},
    {header:"Effective Privacy Policy and Changes in the Privacy Policy",content:(<Sections.EppAndC/>)},
    {header:"Third Party Sites, Apps, Products, Services - Offline and Other Activities",content:(<Sections.TpsApps/>)},
    {header:"Other Agreements",content:(<Sections.OtherAgreements/>)},
    {header:"Contact Information",content:(<Sections.ContactInformation/>)},
    {header:"Your Right to Access, Correct, and Update your Personal Information",content:(<Sections.RightToAccess/>)},
    {header:"How We Collection Your Information",content:(<Sections.HowCollectInfo/>)},
    {header:"Cross-Referencing Your Personal Information",content:(<Sections.CrossReferencePersonalInfo/>)},
    {header:"Protection Of Your Personal Information",content:(<Sections.ProtectingPersonalInfo/>)},
    {header:"Use of Personal Information",content:(<Sections.UseOfPersonalInfo/>)},
    {header:"Your Choices With Respect To Personal Information",content:(<Sections.YourChoicesToPersonalInfo/>)},
    {header:"Disclosure of Your Information",content:(<Sections.DisclosureOfYourPersonalInfo/>)},
    {header:"Tracking/Information We Collect Through Automatic Data Collection Technologies",content:(<Sections.TrackingInfo/>)},
    {header:"Complaints",content:(<Sections.Complaints/>)},
    {header:"Third Party Links",content:(<Sections.ThirdPartyLinks/>)},
    {header:"PRIVACY STATEMENT FOR CALIFORNIA RESIDENTS",content:(<Sections.PrivacyStatementForCal/>)}
]