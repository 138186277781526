

import {connect} from "react-redux";
import Form from "Components/pages/SignUp/Form";
import { registerUser, setRegisterUserSuccess } from "../actions";
import { getRegisterUserSuccess } from "../selectors";

const mapStateToProps = (state, {}) => {
    return {
        isSuccess: getRegisterUserSuccess(state)
    }
}

const mapDispatchToProps = (dispatch, {}) => {
    return {
        registerUser: data => dispatch(registerUser(data)),
        closeSuccessModal: () => dispatch(setRegisterUserSuccess(null))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);

