import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from "./styles.module.scss";

const Loader = ({ isLoading, children }) => {
    if (!isLoading) return children
    return (
        <>
            <div className={styles.container}>
                <CircularProgress color={"inherit"} />
            </div>
            {children}
        </>
    );
}

export default Loader;