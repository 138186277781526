export const formatUnitApplicationData = ({
    unitId,
    bankName, checkingAccountNumber,
    savingsAccountNumber, otherIncomeSource,
    otherIncomeAmount: amountPerYear, collegeAttended,
    collegeMajor: major, collegeGraduation: yearGraduation,
    collegeBusinessRef: businessRef, collegeRefPhoneNumber,
    collegePersonalReference: personalReference,
    ...data
}) => ({
    unitId,
    // personal info
    additionalOccupants: data?.additionalOccupants?.split(","),
    howDidYouHear: data?.heardFrom,
    leaseTerm: data?.leaseTerm,
    moveInDate: data?.moveInDate?.format("YYYY-MM-DD"),
    moveInRequest: data?.moveIn?.value,


    // Residence History
    landlordReferee: { name: data?.currentLandlord, phone: data?.landlordPhone, address: data?.currentAddress },
    reasonsForMoving: data?.reasonForMoving,
    currentAddressTerm: data?.currentAddressDuration,
    currentRent: data?.currentRent?.replace(/,/g, ''),


    // Employment info: 
    businessAddress: data?.currentBusinessAddress,
    occupationStartDate: data?.occupationStartDate?.format("YYYY-MM-DD"),
    occupationEndDate: data.occupationEndDate?.format("YYYY-MM-DD"),
    salary: data?.currentSalary?.replace(/,/g, ''),

    // Financial Information
    otherIncomeSource,
    amountPerYear: amountPerYear?.replace(/,/g, ''),

    // College
    collegeAttended,
    major,
    yearGraduation,
    businessRef,
    personalReference,


    files: {
        // Attachments
        signedDosFormFile: data?.tenantSignedDOSForm?.[0],
        mostRecentFederalTaxReturnFile: data?.tenantCPALetter?.[0],
        letterOfEmploymentFile: data?.tenantLetter?.[0],
        threeRecentPayStubsFile: data?.tenantPayStubs?.[0],
        mostRecentAccountStatementFile: data?.tenantBankStatements?.[0],
        letterOfReferenceFromCurrentLandlordFile: data?.tenantLandlordReference?.[0],
        fullCreditReportFile: data?.tenantCreditReport?.[0],
        photoIdFile: data?.tenantPhotoID?.[0],

        // GuaranrtorAttachments
        guarantorRentalApplicationFile: data?.rentalApplication?.[0],
        guarantorMostRecentFederalTaxReturnFile: data?.guarantorCPALetter?.[0],
        guarantorNotarizedFormFile: data?.guarantorForm?.[0],
        guarantorCreditReportFile: data?.guarantorCreditReport?.[0],
        guarantorPhotoIdFile: data?.guarantorPhotoID?.[0],
    },

});

export const formatUnitApplicationTenantData = (data) => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: `+${data.phone}`,
    workPhone: data.workPhone && `+${data.workPhone}`,
    [data.documentType?.value]: data.documentNumber,
    occupation: data?.currentOccupation,
})