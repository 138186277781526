import { safeSelector } from "../../helpers/safeSelector";
import { IS_SUCCESS } from "../../Redux/consts";
import { PROPERTIES, TENANT_APPLICATION_SUBMISSION_, UNIT } from "./consts";

const getReducer = (state) => state.get(PROPERTIES)

export const getUnitSelector = safeSelector(
    getReducer,
    state => state.get(UNIT)
);

export const getSubmitUnitApplicationSuccess = safeSelector(
    getReducer,
    state => state.get(TENANT_APPLICATION_SUBMISSION_ + IS_SUCCESS)
)