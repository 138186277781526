import React, { useState } from 'react';
import styles from './styles.module.scss';
import classes from 'classnames';
import divider from 'assets/images/longGreyDivider.svg';
import PaperClip from 'assets/images/paperClip.svg';
import TenantsTab from './TenantsTab';
import LandlordsTab from './LandlordsTab';
import ManagementCompanyUsersTab from './ManagementCompanyUsersTab';
import StaffUsersTab from './StaffUsersTab';

const SecondStep = () => {

   const [currentLink, setCurrentLink] = useState(1);

   const buildingsSteps = [
      {
         number: 1,
         header: 'Tenants',
         component: <TenantsTab />
      },
      {
         number: 2,
         header: 'Landlords',
         component: <LandlordsTab />
      },
      {
         number: 3,
         header: 'Management Company Users',
         component: <ManagementCompanyUsersTab />
      },
      {
         number: 4,
         header: 'Staff Users',
         component: <StaffUsersTab />
      },
      {
         number: 5,
         header: 'My Service Providers',
         component: <TenantsTab />
      },
      {
         number: 6,
         header: 'Resources',
         component: <TenantsTab />
      }
   ]

   return <div className={styles.root}>
      <span className={styles.uploadRemark}>Add Users or Upload CSV.</span>
      <div className={styles.linksWrapper}>
         <div className={styles.links}>
            {buildingsSteps.map(step => {
               let isCurrentLink = currentLink === step.number;
               return (<div className={styles.linkWrapper}>
                  <span onClick={() => setCurrentLink(step.number)} className={isCurrentLink ? classes(styles.currentLink, styles.link) : styles.link}>{step.header} </span>
                  {isCurrentLink && <div className={styles.underline} />}</div>)
            }
            )}
         </div>
         <img src={divider} className={styles.divider} />
        
         {buildingsSteps[currentLink - 1].component}
      </div>
   </div >
};

export default SecondStep;
