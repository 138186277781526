import React from 'react';

import Heading from 'Components/Common/Heading';

import MultiPartFormContainer from 'Components/MultiPartContainer';
import { useGetInputProps } from 'hooks/useGetInputProps';
import Input from 'Components/Common/Input';
import styles from "../styles.module.scss";
import DateField from '../../../Common/Form/Fields/DateField';
import { guarantorNote } from '..';
import { BuildingAddress } from '../UnitApplicationFormAttachments';
import AmountInput from '../../../Common/Form/Fields/AmountInput';

const UnitApplicationFormEmploymentInformation = ({ onNext, onPrev, setValue, values, heading, headingPrepender, unitAddress }) => {
    const getInputProps = useGetInputProps({ values, setValue, inputWrapperStyle: styles.input });

    return (
        <MultiPartFormContainer
            containerClass={styles.formSectionContainer}
            onNext={onNext}
            onPrev={onPrev}
            headingPrepender={headingPrepender}
            heading={heading}
        >
            <div className={styles.divSeparator}>
                <BuildingAddress address={unitAddress} />
                <Heading className={styles.sectionSubheading} text={"Employment Information"} />
                <Input label={"Current employer"} {...getInputProps("currentEmployer")} />
                <Input label={"Occupation"} {...getInputProps("currentOccupation")} />
                <Input label={"Business address"} {...getInputProps("currentBusinessAddress")} />
                <div className={styles.multiInputRow}>
                    <DateField
                        label={"Start date"}
                        onChange={val => setValue("occupationStartDate", val)}
                        value={values?.occupationStartDate}
                        inputProps={{ inputWrapperStyle: styles.input }}
                    />
                </div>
                <AmountInput label={"Annual Salary"} {...getInputProps("currentSalary")} />
                <Input label={"Supervisor full name"} {...getInputProps("occupationSupervisor")} />
            </div>
            {guarantorNote}
        </MultiPartFormContainer>
    )
};

export default UnitApplicationFormEmploymentInformation