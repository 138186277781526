import React from 'react';
import Heading from 'Components/Common/Heading';

import MultiPartFormContainer from 'Components/MultiPartContainer';
import { useGetInputProps } from 'hooks/useGetInputProps';
import Input from 'Components/Common/Input';
import styles from "../styles.module.scss";
import { guarantorNote } from '..';
import { BuildingAddress } from '../UnitApplicationFormAttachments';
import AmountInput from '../../../Common/Form/Fields/AmountInput';
import PhoneInput from '../../../Common/Form/Fields/PhoneInput';


const UnitApplicationFormFinancialInformation = ({ onNext, onPrev, setValue, values, heading, headingPrepender, unitAddress }) => {
    const getInputProps = useGetInputProps({ values, setValue, inputWrapperStyle: styles.input });
    return (
        <MultiPartFormContainer
            containerClass={styles.formSectionContainer}
            onNext={onNext}
            onPrev={onPrev}
            headingPrepender={headingPrepender}
            heading={heading}
        >
            <div className={styles.divSeparator}>
                <BuildingAddress address={unitAddress} />
                <Heading className={styles.sectionSubheading} text={"Financial Information"} />
                <Input label={"Other income source"} {...getInputProps("otherIncomeSource")} />
                <AmountInput label={"Amount (per Year)"} {...getInputProps("otherIncomeAmount")} />
            </div>
            <div>
                <Heading className={styles.sectionSubheading} text={"Other"} />
                <div className={styles.divSeparator}>
                    <Input label={"College attended"} {...getInputProps("collegeAttended")} />
                    <Input label={"Major"} {...getInputProps("collegeMajor")} />
                    <Input label={"Year graduation"} {...getInputProps("collegeGraduation")} />
                    <Input label={"Business Ref/CPA/Attorney"} {...getInputProps("collegeBusinessRef")} />
                    <PhoneInput label={"Phone number"} {...getInputProps("collegeRefPhoneNumber")} />
                    <Input label={"Personal reference"} {...getInputProps("collegePersonalReference")} />
                </div>
            </div>
            {guarantorNote}
        </MultiPartFormContainer>
    )
};

export default UnitApplicationFormFinancialInformation