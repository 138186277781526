import React,{ useState } from 'react';
import styles from './styles.module.scss';
import Collapsible from 'react-collapsible';
import HeaderComponent from './CollapsibleHeader';
import classes from 'classnames';


const CollapsibleComponent = ({ header, content }) => {
    const [open, setOpen] = useState(false);

    const handleTriggerClick = () => {
      setOpen(!open);
    };

    return <div className={classes({[styles.root]: true, [styles.isRootOpen]: open })}>
        <Collapsible 
            trigger={<HeaderComponent header={header} open={open} />}
            {...{ open, handleTriggerClick, triggerTagName:'div'  }}
        >
            <div className={styles.content} >
                {content}
            </div>
        </Collapsible>
    </div >;
};

export default CollapsibleComponent;