import { gql } from "graphql-request"

export const loadUnitGql = gql`
query units($id: ID) {
    unit(id: $id) {
      pk
      price
      vacancyDate
      id
      unitNumber
      building {
        fullAddress
        manager {
          fullName
          phone
          email
          workHours{
            day
            start
            end
          }
        }
      }
    }
  }
 `