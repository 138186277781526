import React, { useState } from "react";
import classes from 'classnames';
import styles from './styles.module.scss'
import logo from 'assets/images/logo.svg'
import burgerMenu from 'assets/images/burger-menu.svg'
import closeBurgerMenu from 'assets/images/close-burger-menu.svg'
import logoWhite from 'assets/images/logo-white.svg';
import MenuLink from "../MenuLink";
import { useIsOpen } from "hooks/useIsOpen";

const MobileMenu = ({menus}) => {
  const {close, toggle,isOpen} = useIsOpen()
  return (
    <div className={classes(styles.MobileMenuWrap,{
      [styles.parentMenuOpen]: isOpen
    })}>
      <div className={classes(styles.MobileMenuButton,{
          [styles.menuOpen]: isOpen
        })}>
        <img src={isOpen ? logo : logoWhite} alt=""/>
        <img onClick={toggle} src={isOpen ? closeBurgerMenu : burgerMenu}/>
      </div>
      {
        isOpen && (
          <div className={styles.links}>
            {
              menus.map((menu, index)=>
                <MenuLink key={index} menu={menu} styles={styles} handleMenu={close}/>
              )
            }
          </div>
        )
      }
      </div>
    )
  }
export default MobileMenu;