import * as yup from 'yup';

export const initialValues = () => {
    return {
        file: null,
        building: "",
        unit: "",
        floor: "",
        rentAmount: "",
        amenities: "",
        squareFeet: "",
        rentType: "",
        buildingImage: [],
        bedroom: 2,
        bathroom: 1
    }
};

export const schema = yup.object().shape({
    file: yup.mixed()
        .nullable()
        .required('A file is required'),
    building: yup
        .string()
        .max(200)
        .required('Select a building')
        .label('Building'),
    unit: yup
        .number()
        .required('Enter valid Unit Number')
        .label('Unit #'),
    floor: yup
        .number()
        .required('Enter valid floor number')
        .label('Floor'),
    rentAmount: yup
        .number()
        .required('Enter valid rent amount')
        .label('Rent Amount'),
    amenities: yup
        .array()
        .required('Select valid Amenities')
        .label('Amenities'),
    squareFeet: yup
        .number()
        .required('Enter valid Square feet')
        .label('Sqft'),
    rentType: yup
        .string()
        .required('Enter rent type')
        .oneOf(['Free Market', 'Paid Market']),
    buildingImage: yup
        .mixed()
        .required("You need to provide a file")
        .test("type", "Only the following formats are accepted: .jpeg, .jpg and .png", (value) => {
            return value && (
                value[0].type === "image/jpeg" ||
                value[0].type === "image/png"
            );
        }),
    bedroom: yup
        .number()
        .required('Enter valid bedroom number')
        .label('Bedroom'),
    Bathroom: yup
        .number()
        .required('Enter valid bathroom number')
        .label('bathroom'),
});