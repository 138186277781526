import React from 'react';
import SiteHeader from './../Partials/SiteHeader';
import SiteFooter from './../Partials/SiteFooter';
import ScrollTop from './../Partials/ScrollTop';

class MainLayout extends React.Component {
   render(){
     return (
       <>
         <SiteHeader />
            <main>{this.props.children}</main>
         <SiteFooter withBackground/>
         <ScrollTop/>
       </>
     )
   }
 }
 export default MainLayout;