import React from 'react';
import { compact } from "lodash";
import { useTabs } from "hooks/useTabs";
import { useForm } from "hooks/useForm";
import { useOnLoad } from "hooks/useOnLoad";

import tigraLogo from "assets/images/tigra_logo.svg"
import UnitApplicationFormPersonalInfo from './UnitApplicationFormPersonalInfo';
import UnitApplicationFormResidenceHistory from './UnitApplicationFormResidenceHistory';
import UnitApplicationFormEmploymentInformation from './UnitApplicationFormEmploymentInformation';
import UnitApplicationFormFinancialInformation from './UnitApplicationFromFinancialInformation';
import UnitApplicationFormHome from 'Containers/UnitApplicationForm/UnitApplicationFormHome';
import UnitApplicationFormAttachments from 'Containers/UnitApplicationForm/UnitApplicationFormAttachments';
import TabularHeader from '../../Common/TabularHeader';

import styles from "./styles.module.scss"
import { useParams } from 'react-router-dom';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

const tabs = [
    { Component: UnitApplicationFormHome, },
    { Component: UnitApplicationFormPersonalInfo, headerText: "Personal Information", },
    { Component: UnitApplicationFormResidenceHistory, headerText: "Residence history", },
    { Component: UnitApplicationFormEmploymentInformation, headerText: "Employment Information", },
    { Component: UnitApplicationFormFinancialInformation, headerText: "Financial Information", },
    { Component: UnitApplicationFormAttachments, headerText: "Attachments" }
]

const UnitApplicationForm = ({ unit, onLoad: _onLoad }) => {
    let { unitId } = useParams();
    const { Component, toNextTab, toPrevTab, activeTabIndex } = useTabs(tabs);
    const { form, setValue } = useForm();

    const onLoad = () => {
        _onLoad(unitId);
        setValue("unitId", unitId)
    }

    useOnLoad(() => onLoad(unitId));
    useScrollToTop([Component])

    return (
        <div className={styles.container}>
            {activeTabIndex > 0 &&
                <TabularHeader
                    tabs={compact(tabs?.map(({ headerText }) => headerText && ({ text: headerText })))}
                    containerClass={styles.header}
                    activeIndex={activeTabIndex - 1}
                />
            }
            <Component
                onNext={toNextTab}
                onPrev={toPrevTab}
                values={form}
                setValue={setValue}
                unitNumber={unit?.unitNumber}
                unitAddress={unit?.building?.fullAddress}
                heading={`Rental application to Unit ${unit?.unitNumber}`}
                headingPrepender={<img className={styles.logo} src={tigraLogo} />}
            />
        </div>
    )
}

export const guarantorNote = (
    <label className={styles.guarantorNote}>
        <strong>Important note:</strong> If you have a guarantor for this application,
        please have their filled out application and necessary documents
        ready to upload at the end of this application
    </label>
)

export default UnitApplicationForm;