import React from 'react';
import styles from './styles.module.scss';

const ContactDetail = ({ icon, header, detail, detailType }) => {
   return (
      <div className={styles.root}>
         <div>
            <img src={icon} alt="" />
            <span className={styles.header}> {header}</span>
         </div>
         <a href={detailType && `${detailType}:${detail}`} className={styles.detail}>{detail}</a>
      </div>);
};

export default ContactDetail;