import React from 'react';
import Modal from 'Components/Common/Modal';

const TenantLoginVerificationSecondStep = ({ ...props }) => {
    return (
        <Modal
            title='Create a New Password'
            buttonText='Submit'
            inputs={[{ placeholder: 'New Password', type: 'password' }, { placeholder: 'Confirm New Password', type: 'password' }]}
            transparent
            {...props}
        />
    )
};
export default TenantLoginVerificationSecondStep;