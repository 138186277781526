export const DAYS = {
    SUNDAY: 1,
    MONDAY: 2,
    TUESDAY: 3,
    WEDNESDAY: 4,
    THURSDAY: 5,
    FRIDAY: 6,
    SATURDAY: 7,
};

export const stringifyEnumKey = key => {
    return key
        ? key
            .split('_')
            .map(w => `${w[0].toUpperCase()}${w.substr(1).toLowerCase()}`)
            .join(' ')
            .split("/").map(str => `${str[0].toUpperCase()}${str.substr(1).toLowerCase()}`).join('/') //To-Do: Improve this?
        : '';
};

export const stringifyEnumValue = (enu, value) => {
    const key = Object.keys(enu).find(k => enu[k] === value);
    if (key) {
        return stringifyEnumKey(key);
    }
    return '';
};

export const formatEnumToSelectOptions = enumerator => Object.keys(enumerator).map(key => ({
    key: enumerator[key],
    title: key
        .split('_')
        .map(str => `${str[0].toUpperCase()}${str.substr(1).toLowerCase()}`)
        .join(' '),
}));
