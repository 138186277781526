import React from 'react';
import { DatePicker, } from '@mui/x-date-pickers/DatePicker';

import { useIsOpen } from 'hooks/useIsOpen';
import _, { noop } from 'lodash';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Input from '../../../Input';

const DateField = ({ onChange = noop, value, containerProps, inputProps: _inputProps, label, }) => {
    const { isOpen, open, close } = useIsOpen();

    return (
        <DatePicker
            value={value}
            open={isOpen}
            onChange={onChange}
            onClose={close}
            renderInput={({ inputProps, inputRef }) => (
                <div onClick={open} {...containerProps}>
                    <Input
                        label={label}
                        inputRef={inputRef}
                        inputFormat="MM/dd/yyyy"
                        endAdornment={<CalendarIcon />}
                        {..._inputProps}
                        {...inputProps}
                    />
                </div>
            )
            }
        />
    )
}

export default DateField;