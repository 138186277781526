import React from "react";
import styles from "./styles.module.scss";
import MinusCircle from '../../../assets/images/minusCircle.svg';
import PlusCircle from '../../../assets/images/plusCircle.svg';
const QuantityWrapper = ({ name, addIcon=PlusCircle, subtractIcon=MinusCircle, value, handleSubtract, handleAdd }) => {
    return (
        <div className={styles.quantityWrapper}>
            <span className={styles.product}>{name}</span>
            <div className={styles.counter}>
                <i className={styles.icon} onClick={handleSubtract}>
                    <img src={subtractIcon} />
                </i>
                <span className={styles.countingNumbers}>{value}</span>
                <i className={styles.icon} onClick={handleAdd}>
                    <img src={addIcon} />
                </i>
            </div>
        </div>
    );
};
export default QuantityWrapper;
