export const WINDOWS_PHONE = "windows-phone"
export const ANDROID = "android"
export const IOS = "ios"

const device = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return WINDOWS_PHONE;
  }
  if (/android/i.test(userAgent)) {
    return ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }
  return null;
}

export const DEVICE_OS = device();