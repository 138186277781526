import React, { useState } from 'react';

import { changeUserPassword } from 'api/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useInput } from 'hooks/useInput';
import { useIsOpen } from 'hooks/useIsOpen';
import { useQuery } from 'helpers/window';
import TemporaryPasswordModal from '../../Modals/TemporaryPassword';
import NewPasswordModal from '../../Modals/NewPassword';
import { toastr } from 'react-redux-toastr';
import { useOnLoad } from 'hooks/useOnLoad';
import Card from 'Components/Common/Card'

const ResetTemporaryPasswordModals = () => {
    const { isOpen: displayTempPasswordModal, open: openTempPasswordModal, close: closeTempPasswordModal } = useIsOpen()
    const { isOpen: displaySuccessModel, open: openSuccessModel, close: closeSuccessModal } = useIsOpen()
    
    const { isOpen: displayNewPasswordModal, open: openNewPasswordModal, close: closeNewPasswordModal } = useIsOpen()
    const [token, setToken] = useState("");
    const [currentPasswordProps, currentPassword] = useInput()
    const [newPasswordProps, newPassword] = useInput();
    const [newPasswordConfirmationProps, newPasswordConfrimation] = useInput();

    const location = useLocation();
    const params = useQuery(location);
    const history = useHistory();

    const handleSetCurrentPassword = () => {
        if (!currentPassword) return toastr.error("Please enter temporary password.")
        closeTempPasswordModal()
        openNewPasswordModal();
    };

    useOnLoad(() => {
        try {
            const token = params.get("token");
            if (token) {
                setToken(token)
                history.push("/signup/reset-password");
                openTempPasswordModal()
            }
        } catch (e) {
            console.log(e)
        }
    });

    const handleChangePassword = async () => {
        if (newPassword !== newPasswordConfrimation) return toastr.error("Passwords do not match.")
        const res = await changeUserPassword(token, { currentPassword, newPassword });
        if (res?.changePassword?.success) return openSuccessModel();
    }

    return (
        <>
            {displayTempPasswordModal &&
                <TemporaryPasswordModal
                    onClick={(currentPassword) => handleSetCurrentPassword(currentPassword)}
                    inputs={[{ placeholder: 'Temporary Password', type: "password", ...currentPasswordProps }]}
                />
            }
            {displayNewPasswordModal &&
                <NewPasswordModal
                    onClick={handleChangePassword}
                    inputs={[
                        { placeholder: 'New Password', type: "password", ...newPasswordProps },
                        { placeholder: 'Confirm New Password', type: "password", ...newPasswordConfirmationProps }
                    ]}
                />
            }
            {displaySuccessModel && (
                <Card
                    title='Password reset successfully.'
                    content='Password reset successfully. You can now sign in to your Tigra app with your new password and start managing your properties!'
                    buttonText='Back to homepage'
                    close={()=>closeSuccessModal()}
                    clicked={()=>history.push("/")}
                    transparent
                />
            )}
        </>
    )
}

export default ResetTemporaryPasswordModals