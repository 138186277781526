import { useEffect } from 'react'

export const useScrollToTop = (deps = []) => {

    const scrollToTop = () => window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    useEffect(() => {
        scrollToTop()
    }, deps)
    return scrollToTop
}