import React from "react";
import styles from './styles.module.scss'


export const RadioCircle = ({isChecked}) => {
    return(isChecked ? 
        <div className={isChecked ? styles.radioButton__isSelected : styles.radioButton}>
        <div className={styles.radioButtonInnerCircle}/></div> : <div className={styles.radioButton}/>
    )
}
export const CheckBox = ({isChecked, icon}) => {
    return(
        <div className={isChecked ? styles.checkBox__isSelected : styles.checkBox}>{isChecked ? icon : null}</div>
    )
}


const TabListButton = ({ isChecked, text, icon, onButtonPress, isRadio, isCheckBox}) => {
    return (
        <>
            <div className={isChecked ? styles.tabListButton__isSelected : styles.tabListButton}
                onClick={()=>onButtonPress()}>
                <div className={styles.textContainer}>
                   <p className={isChecked ? styles.buttonText__isSelected : styles.buttonText}>{text}</p>
                </div>
                {isCheckBox && <CheckBox isChecked={isChecked} icon={icon}/>}
                {isRadio && <RadioCircle isChecked={isChecked} />}
            </div>
        </>
    );
}


export default TabListButton