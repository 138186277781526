import React from "react";
import styles from './styles.module.scss';

export const Definitions = () => {
    return <>
        <ol type="A" className="ds">
            <li><strong>"Affiliates"</strong>: any organization, company or sole proprietorship of which the TIGRA has an ownership or pecuniary interest in.</li>
            <li><strong>"Data"</strong>: any information collected by the TIGRA, including, but not limited to, e-mail addresses, website URLs and login information.</li>
            <li><strong>"Personal Information"</strong>: information that is used to identify a person whom such information pertains, such as name, postal address, e-mail address, telephone number, social security number or other identifier.</li>
            <li><strong>"Representatives"</strong>:  any member, manager, partner, director, officer, employee, attorney or advisor of the TIGRA.</li>
        </ol>
        <p>Capitalized Terms used herein but not defined have the meaning as described in TIGRA's Terms of Service (“TOS").</p>
    </>;
};

export const PpInGeneral = () => {
    return <>
        <div>
            <h3>YOU ARE OVER THE AGE OF 16</h3>
            <p>TIGRA's Goods and Services are not intended for children under 16 years of age. If you are under the age of 16, you may not provide any person information to us through any of our features, including registering an account, utilizing our services, using our interactive or public comment features. We do not knowingly collect personal information from children under 16.  If we learn we have collected or received personal information from a child under 16, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us.  <em><small>See Section D for TIGRA's contact information.</small></em></p>
        </div>
        <div>
            <h3>YOU'RE RESPONSIBILITY FOR YOUR EMPLOYEES' USAGE OF TIGRA'S GOODS AND SERVICES</h3>
            <p>You may be administering TIGRA's Goods and Services for your employee end users or other agents acting on your behalf.  As such, you may be handling over control of your account to such users, as well as providing them with access to your (or your customer's) data and activity.  TIGRA is not responsible for the privacy and security practices of you and your employees.  Furthermore, this Privacy Policy does not apply to the privacy and security practices of any third party.</p>
        </div>
        <div>
            <h3>YOUR INFORMATION – WHAT IT IS, HOW WE GET IT, AND WHAT CAN YOU DO IF YOU OBJECT</h3>
            <p>This policy describes the type of information TIGRA may collection from you or that you may provide when you visit or use a TIGRA Good and Service.</p>
            <p>This Privacy Policy applies to information TIGRA collects by or from the following sources:</p>
            <ul>
                <li>a TIGRA website ("Site")</li>
                <li>a TIGRA application for smartphones and smart;</li>
                <li>email, text, or other electronic messaging between you and a TIGRA Goods and Services;</li>
                <li>when you interact with our advertising and applications on third-party websites and services.</li>
            </ul>

            <p>TIGRA collects several types of information from and about users, including the following information:</p>
            <ul>
                <li>Information that personally identifies you</li>
                <li>Information that personally identifies a third party</li>
                <li>Information that is about you or a third party but does not personally identify</li>
                <li>Information about your hardware, software, internet connection and usage details.</li>
            </ul>
            <p>All Personal Information collected and maintained by the TIGRA will be subject to this Privacy Policy.</p>
            <p>The Personal Information collected includes the following:</p>
            <ul className="ds">
                <li>
                    <strong>Contact Information &amp; Identification:</strong> name, address, telephone number, email address, and emergency contact information
                </li>
                <li> <strong>Personal Details:</strong> date of birth, social security number, driver's license/state-issued ID/military ID/passport number, employment history/income, gender, and marital status</li>
                <li> <strong>Financial Account Information:</strong> bank account, credit/debit card, and bank account information</li>
                <li> <strong>Property Management Information, Data, and Documents:</strong> tenant leases, tenant payment history, tenant screen process, tenant communications (including notices, requests for service, complaints, violations), registrations, utility report (including consumption, bills, payment), rent/fee payment receipts, and other Client-generated content related to property management and resident history</li>
                <li> <strong>Credentials and Authentication Information:</strong> user names, passwords, password hints and security questions</li>
                <li> <strong>Technical Information:</strong> IP address, device and hardware specifications, internet connection, equipment used to access our Goods and Services, and web browser type</li>
                <li> <strong>Usage Data:</strong> logging data that tracks service access, usage, performance metrics, and error reports</li>
            </ul>

        </div>
        <div className={styles.warningBox}>
            If you object to TIGRA's Privacy Policy, immediately discontinue <br/>your use of <br/>TIGRA's Goods and Services.
        </div>

    </>;
};

export const EppAndC = () => {
    return <>
        <p>
        Only the Privacy Policy currently posted governs TIGRA's use of the Data, not the privacy policy in effect at the time the Data was collected.  TIGRA may amend, change, and/or modify the Privacy Policy at its sole discretion and will provide notice of all changes in the Privacy Policy through indications on the relevant TIGRA Goods and Services. If, after a change in the Privacy Policy, you wish to alter the ways in which the TIGRA is allowed to use your Personal Information, please contact TIGRA in the manner provided for in Section D.
        </p>
        <p>Your continued use of TIGRA's Goods and Services following the posting of changes constitutes your acceptance of such changes.</p> 
    </>;
};

export const TpsApps = () => {
    return <>
        <p>
        The Privacy Policy does not apply to those websites, applications, products, and services not directly provided by TIGRA.  This includes any software or script provided by a third party and installed or configured by the TIGRA.
        </p>
    </>;
};

export const OtherAgreements = () => {
    return <>
        <p>
        Any conflict between the Privacy Policy and the terms of any other written agreement between you and the TIGRA regarding the Personal Information will be controlled by the terms of the other written agreement.
        </p>
    </>;
};

export const ContactInformation = () => {
    return <>
        <p>Contact to TIGRA may be made to and at:</p>
        <strong>TIGRA INC</strong><br/>
        A limited liability company registered with the state of New York, with an address at:<br/><br/>

        519 East 5<sup>th</sup> Street<br/>
        Suite 17<br/>
        New York, NY 10009<br/>
        <div>
            <p>Phone: 917-543-5222</p>
            <p>Website: <a href="https://tigra.app ">https://tigra.app</a></p>
            <p>Email: <a href="mailto:contact@tigra.app">contact@tigra.app</a></p>
        </div>
    </>;
};

export const RightToAccess = () => {
    return <>
        <p>You may access, correct or modify inaccuracies in your Personal Information and Privacy Preferences at any time.  You may do this via the Site, App, or by contacting the TIGRA as provided in Section D.  Please make sure to include the Personal Information required to identify you and any and all requested modifications to the Personal Information and Privacy Preferences.</p>
        <p>If you delete your User Contributions from the Site or App, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other users of TIGRA's Goods or Services.</p>
    </>;
};

export const HowCollectInfo = () => {
    return <>

        <p>Visitors of a TIGRA's Site or App, may view some limited parts of the various Site and App pages without providing any Personal Information.  If you desire to have TIGRA provide services or goods to you or an organization of which you have representative capacity, TIGRA may ask you to provide certain information about yourself or your organization by filling out and submitting one or more forms.  TIGRA reserves the right to refuse service to you if you are unable or unwilling to provide certain requested Personal Information as determined by TIGRA.</p>

        <p>collect Personal Information through the following means:</p>
        <ol type="i">
            <li>
                you when you provide it to us.
                <p>This includes information provided at the time of registering to use our Site or App, subscribing to a TIGRA service, posting materials, or requesting further services.</p>
            </li>
            <li>
                automatically as you navigate through TIGRA's Goods and Services, such as usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
                <p>This includes your IP address, operating system, browser type, records and copies of your correspondence if you contact us or responses to surveys we might ask you to complete for research or marketing purposes.</p>
            </li>
            <li>
                acquisition from third parties, for example, our business partners.
            </li>
        </ol>
        <p>TIGRA provides services that may require you to accept one or more privacy policies of third party software providers.  It is your duty to familiarize yourself with the privacy policies of the third party software providers.  If you do not accept the privacy policy of the third party software providers, TIGRA reserves the right to refuse services to you.</p>
        <p>You also may provide information to be published or displayed (hereinafter, “posted") on public areas of the Goods or Services, or transmitted to other users of the Goods or Services or third parties (collectively, “User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk. Although TIGRA limits access to certain pages that might be specific to your user account, please be aware that no security measures are perfect or impenetrable. Additionally, TIGRA cannot control the actions of other users with whom you may choose to share your User Contributions. Therefore, TIGRA cannot and does not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>

    </>;
};

export const CrossReferencePersonalInfo = () => {
    return <>
        <p>In order to reduce errors in our database, authenticate our users, prevent fraud and prevent abuse of TIGRA's Goods and Services as well as to provide more consistent, relevant experiences to TIGRA's users, we may on occasion supplement or correct the Personal Information you submit to us and your Internet Protocol (IP) address with information in our other databases or information from third-party sources.  As permissible, the TIGRA may also supplement the Personal Information you provide to us with demographic and other information stored in third-party databases in order to make it more likely that communications TIGRA may send will be of interest to you.</p>
    </>;
};

export const ProtectingPersonalInfo = () => {
    return <>
        <p>The Personal Information that you provide in connection with the use of TIGRA's Goods and Services is protected in numerous ways. Access to your Personal Information collected during the purchasing of a product or service is available through a password and unique user ID selected by you. The password is encrypted. The TIGRA recommends that you do not divulge your password to anyone. In addition, your Personal Information resides on secure servers that only selected personnel and contractors have access to via password. The TIGRA encrypts your personal information as it is transferred to the TIGRA and thereby strives to prevent unauthorized parties from viewing such information.</p> 
	
        <p>Certain Personal information not accessible online by you (e.g., IP address) that you provide is accessible only to selected personnel and contractors via password and is not modifiable by you.</p> 
        <p>The safety and security of your information also depends on you. Where you have chosen a password for access to certain parts of a TIGRA Site or App, you are responsible for keeping this password confidential. Do not share your password with anyone.  TIGRA urges you to be careful about giving out information in public areas of the Goods and Services like message boards. The information you share in public areas may be viewed by any user of the Site and App.</p>
        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although TIGRA does its best to protect your Personal Information, TIGRA cannot guarantee the security of your Personal Information. Any transmission of Personal Information is at your own risk. TIGRA is not responsible for circumvention of any privacy settings or security measures.</p>
    
    </>;
};

export const UseOfPersonalInfo = () => {
    return <>
        <h3>GENERAL USAGE OF PERSONAL INFORMATION</h3>
        <p>TIGRA collects your personal information for a variety of purposes, including:</p>
        <ul style={{listStyleType:'disc'}}>

            <li>	To fulfil the purpose for which the Personal Information was collected and provide the service;</li>
            <li>	To create, maintain, customize, and secure user accounts and registrations;</li>
            <li>	To record, support and facilitate your use of the Site and App;</li>
            <li>To facilitate communications with federal, state, or local government authority for permits, property rentals, property sales, inspection needs and status, construction needs and status, and tenant screening;</li>
            <li>	To integrate with, and provide information to inspectors, contractors, vendors, property managers, tenants, and landlords;</li>
            <li>	To process service requests and transaction; </li>
            <li>	To process financial payments;</li>
            <li>To distribute communications and legally required notices regarding the status of TIGRA Goods and Services via phone (land line or mobile), electronic mail (email) and SMS text messaging;</li>
            <li>	To maintain compliance with federal and state laws related to background screening and financial transactions;</li>
            <li>	To prevent fraud and unlawful use;</li>
            <li>	To register your rights, if any, to technical support or other benefits that may be made available to users;</li>
            <li>	To track your preferences in order to provide an experience customized to your tastes.</li>
        </ul>
        <h3>ADVERTISING, PROMOTIONAL, AND/OR MARKETING USES OF PERSONAL INFORMATION</h3>
        <p>TIGRA may also use your information to contact you about our own and third-parties' goods and services that may be of interest to you. If you do not want for TIGRA to use your information in this way, please adjust your user preferences in your account profile.</p> 
        <p>TIGRA may use the information it has collected from you to enable TIGRA or its affiliates to display advertisements to advertisers' target audiences.</p>
        <p><strong>For California Residents:</strong>  TIGRA does not share personal information it receives from residents of California or from residents of any other place with such other organizations for marketing purposes unless the TIGRA has received an opt-in from you.  See more in Section O.</p>
        <p>The TIGRA may collect information about the use of its Goods and Services and the third party software packages listed therein, such as the number of downloads,  installation services purchased, how many users visit the Site and/or App daily, web pages visited, and the IP address of your computer. This information is generally collected in aggregate form, without identifying any user individually, although IP address and Session ID in relation to purchases may be tracked as part of the TIGRA's customer order review and fraud prevention efforts.  Other exceptions to this, where an individual may be identified individually, are noted in the Privacy Policy or in additional privacy terms connected to specific products and/or services. </p>
        <p>TIGRA may use aggregate, non-identifying statistical data for improving the Site and App and the products and services provided by the TIGRA.</p>
    </>;
};

export const YourChoicesToPersonalInfo = () => {
    return <>
        <p>TIGRA recognizes and appreciates the importance of responsible use of information collected through a TIGRA Good or Service. TIGRA will respect your legal rights regarding access to, and correction and deletion of, your Personal Information. TIGRA may communicate information to you regarding products, services, and special offers available from TIGRA unless you have opted not to receive such communications or such communications are prohibited by law, although in such cases TIGRA may find it necessary to continue to communicate with you regarding your use of a TIGRA Good or Service. In addition, you may receive communications and special offers from selected third party providers, but only if you previously opted in to receive such communications. Except in the particular circumstances described in the Privacy Policy, TIGRA will not provide your name to other companies or organizations without your consent.  If you have opted to receive email communications from our selected third party providers and later wish to discontinue receipt of these emails, please contact those third parties directly to update your preferences. </p>
        <p>TIGRA has implemented mechanisms to provide you with the control over your information:</p>
        <ul style={{listStyleType:'disc'}}>
            <li><strong>TRACKING TECHNOLOGIES AND ADVERTISING.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of a Site or App may then be inaccessible or not function properly.</li>
            <li><strong>DISCLOSURE OF YOUR INFORMATION FOR THIRD-PARTY ADVERTISING.</strong> If you do not want TIGRA to share your Personal Information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by [checking the relevant box located on the form on which we collect your data (the [order form/registration form])/[OTHER OPT-OUT METHOD]]. You can also always opt-out by [logging into the Website and adjusting your user preferences in your account profile][,][ checking or unchecking the relevant boxes] [or by] sending us an email with your request to contact@tigra.app.</li>
            <li><strong>PROMOTIONAL OFFERS FROM THE COMPANY.</strong> If you do not wish to have your [email address/contact information] used by the Company to promote our own or third parties' products or services, you can opt-out by [[checking the relevant box located on the form on which we collect your data (the [order form/registration form])/[OTHER OPT-OUT METHOD]] or at any other time by] [logging into the Site or App and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by] sending us an email stating your request to contact@tigra.app. If TIGRA sent you a promotional email, you may send TIGRA a return email asking to be omitted from future email distributions. </li>
            <li><strong>TARGETED ADVERTISING.</strong> If you do not want TIGRA to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by [OPT-OUT METHOD]. </li>
            </ul>
            <p>TIGRA does not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the <a href="https://optout.networkadvertising.org/?c=1" target="_blank">NAI's website</a>.</p>
        </>;
};

export const DisclosureOfYourPersonalInfo = () => {
    return <>
            <h3>DISCLOSURE PURSUANT TO LEGAL OBLIGATION</h3>
            <p>There are instances in which TIGRA may divulge your Personal Information, such as when required by law, regulation, or litigation. We may also disclose your Personal Information if we determine that such disclosure should be made for reasons of national security, law enforcement, or other issues of public importance. </p>
            <h3>DISCLOSURE FOR YOUR OR ANOTHER CUSTOMER'S PROTECTION</h3>
            <p>TIGRA may disclose your Personal Information if TIGRA reasonably believes disclosure is necessary or appropriate to protect the rights, property, or safety of TIGRA, its customers, or others.  This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
            <h3>DISCLOSURE TO CERTAIN PERSONS</h3>

            <p>TIGRA may disclose Personal Information that it collects or you provide to the following persons:</p>
            <ul style={{listStyleType:'disc'}}>
                <li>to contractors, service providers, and other third parties TIGRA uses to support its business;</li>
                <li>to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of TIGRA's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by TIGRA about its Goods and Services users is among the assets transferred;</li>
                <li>to third parties to market their products or services to you if you have not opted out of these disclosures;</li>
                <li>to fulfill the purpose for which you provide it. As an example, if you give an email address to use the “contact your tenant" feature of our App, TIGRA will transmit the contents of that email and your email address to the recipients.</li>
            </ul>
            <p>If TIGRA enters into any business transaction, restricting, merger, sale or other transferring of assets, we reserve the right to transfer all TIGRA service information, which includes all personal information furnished to a TIGRA Site or App, as part of or in connection with the transaction.</p>
        </>;
};

export const TrackingInfo = () => {
    return <>
            <p>In order to improve the TIGRA's Goods and Services, TIGRA may use "cookies," "web beacons," and similar devices to track your activities. A cookie is a small amount of data that is transferred to your browser by a web server and can only be read by the server that gave it to you. A cookie functions as your identification and enables TIGRA to record your passwords, purchases, and preferences. It cannot be executed as code or deliver viruses. A web beacon is a small transparent gif image that is embedded in an HTML page or email used to track when the page or email has been viewed. A similar method may be used by which a product and service may send data to a server or other remote computing device when a set of user-initiated events occur. This method is similar to a cookie in that it may track your visit and the data can only be read by the server that receives the data. </p>
            <p>TIGRA may use cookies and similar devices to track your use of the Site and App and the products and services, and web servers may automatically log the IP/Internet address of your computer.  The TIGRA does not generally use this information to identify you personally. Exceptions to this are noted in the Privacy Policy.  If you expressly provide consent for the Site and App or the products and/or services to record the unique identifier that you select when you register for various activities on the Site and App or the products and/or services, that unique identifier will be stored on a persistent cookie on your computer and that unique identifier will be linked to your Personal Information. As a result, your navigation path around the Site and App and the products and/or services will be tracked in identifiable form. </p>
            <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Click here [INCLUDE AS LINK TO DO NOT TRACK DISCLOSURES] for information on how you can opt out of behavioral tracking on this website and how we respond to web browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.</p>
        </>;
};

export const Complaints = () => {
    return <>
            <p>TIGRA is serious about your privacy concerns.  If you believe that TIGRA has not complied with the Privacy Policy with respect to your personal information, you may write to TIGRA at the address provided for in Section D above.</p>
        </>;
};

export const ThirdPartyLinks = () => {
    return <>
            <p>TIGRA may include links to third party sites, applications, and services.  These linked services are operated by unaffiliated third parties that have separate and independent privacy policies and terms of service that may materially differ from TIGRA' privacy policies and terms of service.  For example, a third party may have its own notice or disclosure procedures and its own information handling practices.  TIGRA is not responsible for the policies, procedures, use, collection, and handling of your information and data.  TIGRA encourages you to review the third party's policies and practices before submitting any information, data, or communication.  </p>
        </>;
};

export const PrivacyStatementForCal = () => {
    return <>
            <p>
            This Privacy Statement for California Residents supplements the information contained in TIGRA’ Privacy Policy and applies solely to all visitors, users, and others who reside in the State of California (“you”).  This statement has been adopted to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this notice.  By accessing TIGRA’ Site or App or utilizing TIGRA’ products and services, you consent to the information collection and handling practices outlined in this statement.
            </p>
            
            <div className={styles.pslist}>
                <div className="pslist-item">
                    <div className="pslist-item-heading">
                        <strong><span>1)</span>	Your Right to Know What PI is collected, used, shared, or sold</strong>
                    </div>
                    <div className="pslist-item-content">
                        <p>TIGRA’s Site, App, products or services collect and process personal information for business purposes.  The information that is collected identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (collectively deemed “personal information” under the CCPA).  Personal information does not include (i) publicly available information from government records and (ii) de-identified or aggregated consumer information.</p>

                        <h3>PERSONAL INFORMATION THAT IS COLLECTED FROM CALIFORNIA RESIDENTS.</h3>
                        <table className={styles.table}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Category</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Examples</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Collected</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Disclosed To Service Providers</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Identifiers</p>
                                    </td>
                                    <td>
                                        <p>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver&rsquo;s license number, passport number, or other similar identifiers.</p>
                                    </td>
                                    <td>
                                        <p>YES </p>
                                    </td>
                                    <td>
                                        <p>SOME &ndash; what needs to happen to perform a job or a task &ndash; not just for any purpose </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))</p>
                                    </td>
                                    <td>
                                        <p>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver&rsquo;s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.</p>
                                        <p>Note: Some personal information included in this category may overlap with other categories.</p>
                                    </td>
                                    <td>
                                        <p>YES</p>
                                    </td>
                                    <td>
                                        <p>SOME &ndash; what needs to happen to perform a job or a task &ndash; not just for any purpose </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Protected classification characteristics under California or federal law</p>
                                    </td>
                                    <td>
                                        <p>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
                                    </td>
                                    <td>
                                        <p>YES </p>
                                    </td>
                                    <td>
                                        <p>Whatever is relevant </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Commercial information</p>
                                    </td>
                                    <td>
                                        <p>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
                                    </td>
                                    <td>
                                        <p>YES</p>
                                    </td>
                                    <td>
                                        <p>Maybe in the future </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Biometric information</p>
                                    </td>
                                    <td>
                                        <p>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
                                    </td>
                                    <td>
                                        <p>NOT NOW </p>
                                    </td>
                                    <td>
                                        <p>NO</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Internet or other similar network activity.</p>
                                    </td>
                                    <td>
                                        <p>Browsing history, search history, information on a consumer&rsquo;s interaction with a website, application, or advertisement.</p>
                                    </td>
                                    <td>
                                        <p>YES</p>
                                    </td>
                                    <td>
                                        <p>YES</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Geolocation data</p>
                                    </td>
                                    <td>
                                        <p>Physical location or movements.</p>
                                    </td>
                                    <td>
                                        <p>YES </p>
                                    </td>
                                    <td>
                                        <p>YES </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sensory data</p>
                                    </td>
                                    <td>
                                        <p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
                                    </td>
                                    <td>
                                        <p>YES </p>
                                    </td>
                                    <td>
                                        <p>YES</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Professional or employment-related information</p>
                                    </td>
                                    <td>
                                        <p>Current or past job history or performance evaluations.</p>
                                    </td>
                                    <td>
                                        <p>YES </p>
                                    </td>
                                    <td>
                                        <p>Possibly </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))</p>
                                    </td>
                                    <td>
                                        <p>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</p>
                                    </td>
                                    <td>
                                        <p>NO</p>
                                    </td>
                                    <td>
                                        <p>NO</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Inferences drawn from other personal information</p>
                                    </td>
                                    <td>
                                        <p>Profile reflecting a person&rsquo;s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
                                    </td>
                                    <td>
                                        <p>NOT SURE </p>
                                    </td>
                                    <td>
                                        <p>NO</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>TIGRA obtains the categories of personal information listed above from the following categories of sources:</p>
                        <ul style={{listStyleType:'disc',marginLeft:'2em'}}>
                            <li>directly from you</li>
                            <li>indirectly from you</li>
                            <li>other sources</li>
                        </ul>
                        <p>USE OF PERSONAL INFORMATION THAT IS COLLECTED FROM CALIFORNIA RESIDENTS.</p>
                        <p>TIGRA may use, sell, or disclose the personal information we collect for one or more of the following purposes:</p>
                        <ul style={{listStyleType:'disc',marginLeft:'2em'}}>
                            <li>to fulfill or meet the reason you provided the information. For example, if provide your personal information to purchase a product or service, we may use that information to process your payment.</li>
                            <li>to provide, support, personalize, and develop our Goods and Services.</li>
                            <li>to create, maintain, customize, and secure your account with us.</li>
                            <li>to process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
                            <li>to provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
                            <li>to personalize your experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Site, App, third-party sites, and via email or text message (with your consent, where required by law).]</li>
                            <li>to help maintain the safety, security, and integrity of our Goods and Services, databases and other technology assets, and business.’</li>
                            <li>to test, research, analyze, and product development, including to develop and improve our Website, products, and services.</li>
                            <li>to respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                            <li>[OTHER PURPOSES].]</li>
                        </ul>

                        <p>SHARING PERSONAL INFORMATION</p>
                        <p>TIGRA may disclose your personal information to a third party for a business purpose [or sell your personal information, subject to your right to opt-out of those sales ]. When TIGRA discloses personal information for a business purpose, TIGRA enters a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
                        <p>We share personal information with the following categories of third parties:</p>
                        <ul style={{listStyleType:'disc',marginLeft:'2em'}}>
                            <li>Third parties</li>
                            <li>Data aggregators</li>
                            <li>Other third party category</li>
                        </ul>
                        <p>DISCLOSURE OF PERSONAL INFORMATION FOR A BUSINESS PURPOSE</p>
                        <p>In the preceding twelve (12) months, Company has not disclosed personal information for a business purpose.</p>
                        <p>SALES OF PERSONAL INFORMATION</p>
                        <p>In the preceding twelve (12) months, Company has not sold personal information for a business purpose.</p>
                    </div>
                </div>
                
            </div>

            <div className={styles.pslist}>
                <div className="pslist-item">
                    <div className="pslist-item-heading">
                        <strong><span>2)</span>	Your Right to Access Information</strong>
                    </div>
                    <div className="pslist-item-content">
                        <p>You have the right to request that TIGRA disclose certain information to you about TIGRA’ collection and use of your personal information over the past 12 months. Once TIGRA receives and confirms your verifiable consumer request, TIGRA will disclose to you:</p>

                        <ul style={{listStyleType:'disc'}}>
                            <li>The categories of personal information we collected about you.</li>
                            <li>The categories of sources for the personal information we collected about you.</li>
                            <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                            <li>The categories of third parties with whom we share that personal information.</li>
                            <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
                            <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
                            <li>sales, identifying the personal information categories that each category of recipient purchased; and </li>
                            <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient    obtained.</li>
                        </ul>
                        <p>See Section 4 for instructions.</p>
                    </div>
                </div>
                
            </div>

            <div className={styles.pslist}>
                <div className="pslist-item">
                    <div className="pslist-item-heading">
                        <strong><span>3)</span>Your Right to Delete Information</strong>
                    </div>
                    <div className="pslist-item-content">
                        <p>You have the right to request that we delete any of your personal information that TIGRA collected from you and retained, subject to certain exceptions. Once TIGRA receives and confirms your verifiable consumer request, TIGRA will delete (and direct its service providers to delete) your personal information from TIGRA’ records, unless an exception applies.</p>
                        <p>Your deletion request may be denied if its is necessary for TIGRA or its service provider(s) to:</p>
                        <ul style={{listStyleType:'disc'}}>
                            <li>Complete the transaction for which TIGRA collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of TIGRA’ ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform TIGRA’s contract with you;</li>
                            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities;</li>
                            <li>Debug products to identify and repair errors that impair existing intended functionality;</li>
                            <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.;</li>
                            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.);</li>
                            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.;</li>
                            <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us;</li>
                            <li>Comply with a legal obligation;</li>
                            <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
                        </ul>
                        <p>See Section 4 for instructions.</p>
                    </div>
                </div>
                
            </div>

            <div className={styles.pslist}>
                <div className="pslist-item">
                    <div className="pslist-item-heading">
                        <strong><span>4)</span> Exercising Your Rights</strong>
                    </div>
                    <div className="pslist-item-content">
                        <p>To exercise the rights described above, please submit a verifiable consumer request to TIGRA by either:</p>
                        <p>[Emailing us at [contact@tigra.app].]</p>
                        <p>MUST HAVE ANOTHER METHOD</p>
                        <p></p>
                        <p><strong>WHO CAN MAKE REQUEST:</strong>  Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  TIGRA will use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
                        <p><strong>LIMITATION ON NUMBER OF REQUESTS:</strong>  You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>

                        <ul style={{marginLeft:'2em'}}>
                            <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative and </li>
                            <li>Describe your request with sufficient detail that allows TIGRA to properly understand, evaluate, and respond to it.</li>
                        </ul>
                        <p><strong>HOW LONG IT WILL TAKE FOR TIGRA TO RESPOND:</strong>    TIGRA endeavors to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 45 days), TIGRA will inform you of the reason and extension period in writing.  </p>
                        <p><strong>SCOPE OF TIGRA’S RESPONSE TO YOUR REQUEST:</strong>  Any disclosures TIGRA provides will only cover the 12-month period preceding the verifiable consumer request's receipt. Alternatively, TIGRA may provide a response explaining the reason TIGRA cannot comply with the request.</p>
                        <p><strong>COST OF COMPLIANCE:</strong>  TIGRA will not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If TIGRA determines that the request warrants a fee, TIGRA will tell you why it made that decision and provide you with a cost estimate before completing your request.</p>

                    </div>
                </div>
                
            </div>

            <div className={styles.pslist}>
                <div className="pslist-item">
                    <div className="pslist-item-heading">
                        <strong><span>5)</span>	Your Right to Opt-Out</strong>
                    </div>
                    <div className="pslist-item-content">
                        <p>You have the right to direct TIGRA to not sell your personal information at any time (the "right to opt-out"). TIGRA do not sell the personal information of consumers it actually knows are less than 16 years of age  Consumers who opt-in to personal information sales may opt-out of future sales at any time.</p>

                        <p>To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by visiting the following Internet Web page link:</p>

                        <p>[HYPERLINKED URL with the title "Do Not Sell My Personal Information"]</p>

                        <p>Once you make an opt-out request, TIGRA will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back in to personal information sales at any time by:</p>

                        <p>[OPT-IN INSTRUCTIONS OR URL LINK]</p>

                        <p>You do not need to create an account with TIGRA to exercise your opt-out rights. TIGRA will only use personal information provided in an opt-out request to review and comply with the request.</p>
                    </div>
                </div>
                
            </div>

            <div className={styles.pslist}>
                <div className="pslist-item">
                    <div className="pslist-item-heading">
                        <strong><span>6)</span> Right to be Free from Discrimination if You Exercise Your Privacy Rights</strong>
                    </div>
                    <div className="pslist-item-content">
                        <p>TIGRA will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, TIGRA will not:</p>
                        <ul style={{marginLeft:'2em'}}>
                            <li>Deny you its products and services;</li>
                            <li>Charge you different prices or rates for products and services, including through granting discounts or other benefits, or imposing penalties;</li>
                            <li>Provide you a different level or quality of goods or services.</li>
                            <li>Suggest that you may receive a different price or rate for products or services or a different level or quality of goods or services.</li>
                        </ul>
                        <p>However, TIGRA may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive TIGRA offers will reasonably relate to your personal information's value and contain written terms that describe the program's material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time. </p>

                    </div>
                </div>
                
            </div>
        </>;
};