import React, { useRef } from 'react';
import Button from 'Components/Common/Button';
import RenderValue from './RenderValue';
import styles from "./styles.module.scss"
import { noop } from "lodash";

import attachmentIcon from '../../../../../../src/assets/images/attachment.svg'
import deleteIcon from '../../../../../../src/assets/images/trash.svg'

const AttachmentField = ({ setValue = noop, fieldName, value, accept, btnText = "Add File", label, rightLabel, ...props }) => {
    const inputRef = useRef();


    const onRemove = (i) => {
        if (Object.keys(value).length <= 1) return setValue(fieldName, null)
        const files = value.filter((_, index) => index !== i)
        return setValue(fieldName, files);
    };

    const handleAddFiles = (files) => Array.from(files).concat(Array.from(value || []));

    const handleSetValue = (files, fieldName) => {
        setValue(fieldName, handleAddFiles(files));
    }

    return (
        <div className={styles.container}>
            <div className={styles.labelContainer}>
                <label>{label}</label>
                <label>{rightLabel}</label>
            </div>
            {value && Object.values(value).map((item, i) => (
                <RenderValue
                    key={i}
                    iconLeft={attachmentIcon}
                    actionIconRightdeleteIcon={deleteIcon}
                    text={item?.name}
                    onRemove={() => onRemove(i)}
                />
            ))
            }
            <Button onClick={() => inputRef?.current?.click()}>
                {btnText}
            </Button>
            <input
                ref={inputRef}
                onChange={e => handleSetValue(e.target.files, fieldName)}
                type='file'
                accept={accept}
                {...props}
            />
        </div>
    )
}

export default AttachmentField;