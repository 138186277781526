import React from 'react';
import Button from '../Common/Button';
import Heading from '../Common/Heading';
import styles from "./styles.module.scss"

const MultiPartFormContainer = ({ onNext, onPrev, children, prevText = "Back", nextText = "Next", containerClass, heading, headerProps, headingPrepender }) => {
    return (
        <div className={containerClass}>
            {headingPrepender}
            <Heading text={heading} {...headerProps}/>
            {children}
            <div className={styles.footer}>
                <Button onClick={onPrev}>{prevText}</Button>
                <Button onClick={onNext}>{nextText}</Button>
            </div>
        </div>

    )
}

export default MultiPartFormContainer;