import React from 'react';
import { Formik } from 'formik';
import styles from './styles.module.scss';
import { initialValues, schema } from "./schema";
import Input from 'Components/Common/Input';

const FormContainer = ({ }) => {
    return <>
        <Formik
            initialValues={initialValues()}
            validationSchema={schema}
            validate={values => {
                const errors = {};
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <Input name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} error={touched.name && errors.name} label={'Name'} placeholder='Type In...' inputStyle={styles.inputStyle}/>
                    <Input name="userLimit" value={values.userLimit} onChange={handleChange} onBlur={handleBlur} error={touched.userLimit && errors.userLimit} label={'User Limits'} placeholder='Type In...' bottomLine={"*# of users your company allowed to have in the system according to the license."} inputStyle={styles.inputStyle}/>
                    <div className={styles.buttonsWrapper}>
                        <button className={styles.button}>Save</button>
                    </div>
                </form>)}
        </Formik>
    </>
};

export default FormContainer;