import React from 'react';
import { DAYS, stringifyEnumValue } from '../../../../const';
import { converAMPM } from '../../../../helpers/date';
import styles from './styles.module.scss';

const formatDays = (day) => stringifyEnumValue(DAYS, day);
const formatTime = (time) => converAMPM(time.split(":").splice(0, 2).join(":"));

const WorkHoursFeature = ({ start, end, endDay, startDay }) => {
    return (
        <div className={styles.container}>
            {startDay && endDay && <div>{formatDays(startDay)}-{formatDays(endDay)}</div>}
            {start && end && <div>{formatTime(start)}-{formatTime(end)}</div>}
        </div>
    )
};

export default WorkHoursFeature;