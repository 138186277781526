
export const LOAD_UNIT = "LOAD_UNIT";
export const LOAD_UNIT_SUCCESS = "LOAD_UNIT_SUCCESS";

export const SUBMIT_TENANT_UNIT_APPLICATION = "SUBMIT_TENANT_UNIT_APPLICATION";


// actions 
export const TENANT_APPLICATION_SUBMISSION_ = "TENANT_APPLICATION_SUBMISSION_"

// Fields
export const PROPERTIES = "properties";
export const UNIT = "unit";
export const PRICE = "price";
export const VACANCY_DATE = "vacancyDate";
export const ADDRESS = "address";
export const BUILDING = "building";
export const MANAGER = "manager";