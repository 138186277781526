import React, { useState } from 'react';
import styles from './styles.module.scss';
import OnboardingContent from './ContentTemplate';
import FirstStep from './Content/FirstStep';
import SecondStep from './Content/SecondStep';
import ThirdStep from './Content/ThirdStep';
import FourthStep from "./Content/FourthStep"
const Onboarding = () => {
   const [currentStep, setCurrentStep] = useState(1);

   const steps = [
      {
         number: 1,
         description: 'MANAGEMENT COMPANY',
         header: '1/6: ADD MANAGEMENT COMPANY',
         component: <FirstStep />
      },
      {
         number: 2,
         description: 'ADD USERS',
         header: '2/6: Add USERS',
         component: <SecondStep />
      },
      {
         number: 3,
         description: 'ADD BUILDINGS',
         header: '3/6: ADD BUILDINGS',
         component: <ThirdStep />
      },
      {
         number: 4,
         description: 'ADD UNITS',
         header: '4/6: ADD Units',
         component: <FourthStep />
      },
      {
         number: 5,
         description: 'ADD LEASES',
         header: '5/6: ADD LEASES',
         component: <SecondStep />
      },
      {
         number: 6,
         description: 'ADD LEASES',
         header: '6/6: ADD expenses & income',
         component: <SecondStep />
      }
   ]

   return (
      <OnboardingContent currentStep={currentStep} mainSteps={steps} stepChange={step => setCurrentStep(step)} />
   )
};

export default Onboarding;
