import React from 'react';
import Modal from 'Components/Common/Modal';

const NewPasswordModal = ({ close,...props }) => {
    return (
        <Modal
            title='Enter New Password'
            buttonText='Enter New Password'
            transparent
            close={close}
            {...props} />
    )
};

export default NewPasswordModal;