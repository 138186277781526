import React from 'react';
import styles from './styles.module.scss';
import Wizard from './Wizard';
import rightArrow from 'assets/images/ArrowRight.svg';

const OnboardingContent = ({ mainSteps, currentStep, stepChange }) => {
   return <div className={styles.root}>
      <Wizard steps={mainSteps} currentStep={currentStep} stepChange={stepChange} />
      <span className={styles.header}> {mainSteps[currentStep - 1].header} </span>
      {mainSteps[currentStep - 1].component}
      {(currentStep !== 6) && <div className={styles.nextWrapper}>
         <span className={styles.nextText} onClick={() => stepChange(currentStep + 1)}> NEXT </span>
         <img src={rightArrow} className={styles.arrowRight} />
      </div>}
   </div >;
};

export default OnboardingContent;
