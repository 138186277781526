

import {connect} from "react-redux";
import UnitApplicationFormAttachments from "../../../Components/pages/UnitApplicationForm/UnitApplicationFormAttachments";
import { submitTenantUnitApplication } from "../../Properties/actions";
import { getSubmitUnitApplicationSuccess } from "../../Properties/selectors";


const mapStateToProps = (state, {}) => {
    return {
        isSuccess: getSubmitUnitApplicationSuccess(state)
    }
}


const mapDispatchToProps = (dispatch, {}) => {
    return {
        onSubmit: data => dispatch(submitTenantUnitApplication(data)),
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UnitApplicationFormAttachments);

