import { REGISTER_USER, SET_REGISTER_USER_SUCCESS } from "./consts";

export const registerUser = (data) => ({
    type: REGISTER_USER,
    data
})

export const setRegisterUserSuccess = (isSuccess) => ({
    type: SET_REGISTER_USER_SUCCESS,
    isSuccess
})