import React, { useEffect, useState } from 'react';
import ImageInput from "Components/Common/ImageInput";
import InputSelect from 'Components/Common/InputSelect';
import Input from 'Components/Common/Input';
import camera from "assets/images/camera.svg";
import styles from './styles.module.scss';

const LeftColumn = ({ values, touched, errors, handleChange, setFieldValue, handleBlur }) => {
    const inputRef = React.useRef(null);
    const handleClick = (ref) => {
        ref.current.click();
    };

    return (
        <div className={styles.leftColumn}>
            <Input name="buildingAddress" value={values.buildingAddress} onChange={handleChange} onBlur={handleBlur} error={touched.buildingAddress && errors.buildingAddress} label={'Building Address'} placeholder='Address' />
            <Input name="buildingCity" value={values.buildingCity} onChange={handleChange} onBlur={handleBlur} error={touched.buildingCity && errors.buildingCity} label={'Building City'} placeholder='City' />
            <Input name="block" value={values.block} onChange={handleChange} onBlur={handleBlur} label={'Block'} error={touched.block && errors.block} placeholder='st 11' />
            <Input name="zip" value={values.zip} onChange={handleChange} error={touched.zip && errors.zip} onBlur={handleBlur} label={'Zip'} placeholder='00000' />
            <InputSelect onChange={handleChange} value={values.propertyType} id="propertyType" error={touched.propertyType && errors.propertyType} name="propertyType" options={[{ label: 'Home', value: 1 }, { label: 'Office', value: 2 }]} label={'Type of Property'} placeholder='Select Property' />
            <Input name="buildingFar" value={values.buildingFar} onChange={handleChange} error={touched.buildingFar && errors.buildingFar} onBlur={handleBlur} label={'Building Far'} placeholder='5' />
            <Input name="lotDimension" value={values.lotDimension} onChange={handleChange} onBlur={handleBlur} error={touched.lotDimension && errors.lotDimension} label={'Lot Dimension'} placeholder='25 ft”97ft' />
            <Input name="yearBuilt" value={values.yearBuilt} onChange={handleChange} onBlur={handleBlur} error={touched.yearBuilt && errors.yearBuilt} label={'Year Built'} placeholder='Year' />
            <ImageInput accept="image/*" type="file" icon={camera} inputRef={inputRef} handleClick={handleClick} id='buildingImage' name="buildingImage" value={values.buildingImage} onChange={(event) => setFieldValue('buildingImage', [...values.buildingImage, event.currentTarget.files[0]])} error={touched.buildingImage && errors.buildingImage} label={'Add Image'} placeholder='5' onBlur={handleBlur} inputWrapperStyle={styles.inputWrapperStyle} inputStyle={styles.inputStyle}/>
        </div>
    )
}

export default LeftColumn;