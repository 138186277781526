import React from 'react';
import { Formik } from 'formik';
import styles from './styles.module.scss';
import { Divider } from 'Components/Common/Divider';
import InputSelect from 'Components/Common/InputSelect';
import Input from 'Components/Common/Input';
import FileInput from 'Components/Common/FileInput';
import QuantityWrapper from 'Components/Common/QuantityWrapper';
import ImageInput from "Components/Common/ImageInput";
import camera from 'assets/images/camera.svg';
import PaperClip from 'assets/images/paperClip.svg';
import { schema, initialValues } from './schema';

const FormContainer = ({ }) => {
   const inputRef = React.useRef(null);
   const handleClick = (ref) => {
      ref.current.click();
   };
   return (
      <>
         <Formik
            initialValues={initialValues()}
            validationSchema={schema}
            validate={values => {
               const errors = {};
               if (!values.managementaCompany) {
               }
               return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
               setSubmitting(false);
            }}
         >
            {({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
               setFieldValue
            }) => (
               <form onSubmit={handleSubmit}>
                  <FileInput accept=".csv" label={"Import Buildings from File (CSV)"} linkText={"Download CSV Template"} icon={PaperClip} name='file' value={values.file} onChange={handleChange} error={touched.file && errors.file} onBlur={handleBlur} />
                  <Divider />
                  <div className={styles.columns}>
                     <div className={styles.leftColumn}>
                        <InputSelect name="building" value={values.building} onChange={handleChange} onBlur={handleBlur} error={touched.building && errors.building} options={[{ label: 'New york', value: 1 }, { label: 'New jersey', value: 2 }]} label={'Building'} placeholder='Type In...' />
                        <Input name="unit" value={values.unit} onChange={handleChange} onBlur={handleBlur} error={touched.unit && errors.unit} label={'Unit #'} placeholder='Type In...' />
                        <Input name="floor" value={values.floor} onChange={handleChange} onBlur={handleBlur} error={touched.floor && errors.floor} label={'Floor'} placeholder='Type In...' />
                        <Input name="rentAmount" value={values.rentAmount} onChange={handleChange} onBlur={handleBlur} error={touched.rentAmount && errors.rentAmount} label={'Rent Amount'} placeholder='5,000' />
                     </div>
                     <div className={styles.rightColumn}>
                        <Input name="amenities" value={values.amenities} onChange={handleChange} onBlur={handleBlur} error={touched.amenities && errors.amenities} label={'Amenities'} placeholder='Type In...' />
                        <Input name="squareFeet" value={values.squareFeet} onChange={handleChange} onBlur={handleBlur} error={touched.squareFeet && errors.squareFeet} label={'Sqft'} placeholder='Type In...' />
                        <InputSelect id="rentType" name="rentType" value={values.rentType} onChange={handleChange} onBlur={handleBlur} error={touched.rentType && errors.rentType} options={[{ label: 'Free Market', value: 1 }, { label: 'Paid Market', value: 2 }]} label={'Rent Type'} placeholder='Select type...' />
                     </div>
                  </div>
                  <Divider />
                  <ImageInput accept="image/*" type="file" id='buildingImage' name="buildingImage" icon={camera} inputRef={inputRef} handleClick={handleClick} value={values.buildingImage} onChange={(event) => setFieldValue('buildingImage', [...values.buildingImage, event.currentTarget.files[0]])} error={touched.buildingImage && errors.buildingImage} label={'Add Image'} placeholder='5' onBlur={handleBlur} inputWrapperStyle={styles.inputWrapperStyle} inputStyle={styles.inputStyle}/>
                  <Divider />
                  <div className={styles.columns}>
                     <div className={styles.leftColumn}>
                        <QuantityWrapper name="Bedroom" value={values.bedroom} handleAdd={() => setFieldValue('bedroom', ++values.bedroom)} handleSubtract={() => !!values.bedroom && setFieldValue('bedroom', --values.bedroom)} />
                     </div>
                     <div className={styles.rightColumn}>
                        <QuantityWrapper name="Bathroom" value={values.bathroom} handleAdd={() => setFieldValue('bathroom', ++values.bathroom)} handleSubtract={() => !!values.bathroom && setFieldValue('bathroom', --values.bathroom)} />
                     </div>
                  </div>
                  <div className={styles.buttonsWrapper}>
                     <button className={styles.button}>Save & Add Another</button>
                     <button className={styles.button}>Save</button>
                  </div>
               </form>
            )}
         </Formik>
      </>
   )
}
export default FormContainer;