import React from 'react';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import FeaturesSection from './FeaturesSection';
import LeaseManagementSection from './LeaseManagementSection';
import ComingSoon from './ComingSoon';
import MeetTheFounder from './MeetTheFounder';
import ContactUs from './ContactUs';
import ScrollTop from 'Components/Layout/Partials/ScrollTop';

const LandingPage = () => {
   return (<>
      <FirstSection />
      <SecondSection />
      <FeaturesSection />
      <LeaseManagementSection />
      <ComingSoon />
      <MeetTheFounder />
      <ContactUs />
      <ScrollTop />
   </>);

};

export default LandingPage;