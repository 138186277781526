import React from 'react';
import Modal from 'Components/Common/Modal';

const ResetPasswordFirstStep = ({ clicked, ...props }) => {
    return (
        <Modal
            title='Reset Your Password'
            content='Submit your email address and we’ll send you a link to reset your password.'
            buttonText='Send Reset Link'
            inputs={[{ placeholder: 'Email', type: 'text' }]}
            clicked={clicked}
            {...props}
        />
    )
};
export default ResetPasswordFirstStep;