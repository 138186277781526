import React from 'react';

import MultiPartFormContainer from 'Components/MultiPartContainer';
import Input from 'Components/Common/Input';
import Heading from 'Components/Common/Heading';

import TabListSelect from '../../../Common/TabListSelect';
import { useGetInputProps } from 'hooks/useGetInputProps';
import styles from "../styles.module.scss";

import check from '../../../../assets/images/check.svg'
import { guarantorNote } from '..';
import { BuildingAddress } from '../UnitApplicationFormAttachments';
import DateField from '../../../Common/Form/Fields/DateField';
import PhoneInput from '../../../Common/Form/Fields/PhoneInput';
const documentOptions = [{ text: "Driver Licence", value: 'drivingLicense' }, { text: "Passport Number", value: 'passport' }, { text: "SSN Number", value: 'ssn' }];
const moveInOptions = [{ text: 'As Soon as Possible', value: 'asap', icon: <img src={check} /> }, { text: "Flexible/Any date after the apartment becomes available", value: 'flexible', icon: <img src={check} /> }]


const UnitApplicationFormPersonalInfo = ({ onNext, onPrev, setValue, values, unitAddress, heading, headingPrepender }) => {
    const getInputProps = useGetInputProps({ values, setValue, inputWrapperStyle: styles.input });
    return (
        <MultiPartFormContainer
            containerClass={styles.formSectionContainer}
            onNext={onNext}
            onPrev={onPrev}
            heading={heading}
            headingPrepender={headingPrepender}
        >
            <div className={styles.divSeparator}>
                <BuildingAddress  address={unitAddress} />
                <Heading className={styles.sectionSubheading} text={"Personal information"} />
                <Input label={"First name"} {...getInputProps("firstName")} />
                <Input label={"Last name"} {...getInputProps("lastName")} />
                <TabListSelect
                    isRadio={true}
                    values={documentOptions}
                    label={"Document"}
                    className={styles.listSelect}
                    value={values?.documentType}
                    onChange={(val) => setValue("documentType", val)}
                />
                <Input label={"Enter number"} {...getInputProps("documentNumber")} />
                <Input label={"Email"} {...getInputProps("email")} />
                <PhoneInput label={"Phone number (Cell)"} startAdornment={<p>+1</p>} {...getInputProps("phone")} />
                <PhoneInput label={"Phone number (Work)"} startAdornment={<p>+1</p>} {...getInputProps("workPhone")} />
                <Input 
                    label={"Additional occupants of the unit"}
                    {...getInputProps("additionalOccupants")} 
                    bottomLine="Please list the names of any additional
                    occupants of the unit (separated by commas)"
                    />

                <Input label={"How did you hear about us?"} {...getInputProps("heardFrom")} />
            </div>
            <div>
                <Heading className={styles.sectionSubheading} text={"Lease Details"} />

                <label>
                    Lease details written are not final and will be confirmed/discussed with the property manager upon application approval
                </label>
                <br/>
                <br/>

                <div className={styles.divSeparator}>
                    <Input label={"Lease term"} {...getInputProps("leaseTerm")} />
                    <DateField
                        label={"Move in Request"}
                        onChange={val => setValue("moveInDate", val)}
                        value={values?.moveInDate}
                        inputProps={{ inputWrapperStyle: styles.input }}

                    />
                    <TabListSelect
                        isCheckBox={true}
                        values={moveInOptions}
                        value={values?.moveIn}
                        onChange={(val) => setValue("moveIn", val)}
                    />
                </div>
            </div>
            {guarantorNote}
        </MultiPartFormContainer>
    )
};

export default UnitApplicationFormPersonalInfo