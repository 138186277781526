import React, { useState } from 'react';
import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';
import Form from './Form';
import backgroundImage from 'assets/images/SignIn.png';
import SigninVarificationFirstStep from './Modals/SigninVarificationFirstStep';
import SigninVarificationSecondStep from './Modals/SigninVarificationSecondStep';
import ResetPasswordFirstStep from './Modals/ResetPasswordFirstStep';
import ResetPasswordSecondStep from './Modals/ResetPasswordSecondStep';
import ResetPasswordThirdStep from './Modals/ResetPasswordThirdStep';
import TenantLoginVerificationFirstStep from './Modals/TenantLoginVerificationFirstStep';
import TenantLoginVerificationSecondStep from './Modals/TenantLoginVerificationSecondStep';

const SignIn = () => {
   const [isManager, setIsManager] = useState(true);
   const [submitted, setIsSubmitted] = useState(false);
   const [firstLogin, setFirstLogin] = useState(true);
   const [phoneNumberEntered, setPhoneNumberEntered] = useState(false);
   const [resetPasswordClicked, setResetPasswordClicked] = useState(false);
   const [resetEmailEntered, setResetEmailEntered] = useState(false);
   const [emailOpened, setEmailOpened] = useState(false);
   const [tenantFirstloginClicked, setTenantFirstloginClicked] = useState(false);
   const [tenentEmailEntered, setTenentEmailEntered] = useState(false);

   const toggleIsManager = () => {
      setIsManager(!isManager);
   }

   return <div className={styles.root}>
      <div className={styles.bulks}>
         <div className={styles.imageBulk}>
            <img className={styles.backgroundImage} src={backgroundImage} alt="" />
         </div>
         <div className={styles.formBulk}>
            <Form isManager={isManager} toggleIsManager={toggleIsManager} submit={() => setIsSubmitted(true)} />
            <NavLink className={styles.link} to="/signup">Need an account?<span>  SIGN UP </span></NavLink>
            <span className={styles.link} onClick={() => setResetPasswordClicked(true)} >forgot your password?<span>  RESET </span></span>
            {!isManager &&
               <span className={styles.link} onClick={() => { setTenantFirstloginClicked(true); console.log('clicked') }} >first time Log in?<span>  CLICK </span></span>}

            {submitted && firstLogin && <SigninVarificationFirstStep close={() => setIsSubmitted(false)} clicked={() => setPhoneNumberEntered(true)} phoneNumberStep />}
            {phoneNumberEntered && firstLogin && <SigninVarificationSecondStep close={() => setPhoneNumberEntered(false)} transparent />}
            {resetPasswordClicked && <ResetPasswordFirstStep close={() => setResetPasswordClicked(false)} clicked={() => setResetEmailEntered(true)} />}
            {resetEmailEntered && <ResetPasswordSecondStep close={() => setResetEmailEntered(false)} clicked={() => setEmailOpened(true)} />}
            {emailOpened && <ResetPasswordThirdStep close={() => setEmailOpened(false)} />}
            {tenantFirstloginClicked && <TenantLoginVerificationFirstStep close={() => setTenantFirstloginClicked(false)} clicked={() => setTenentEmailEntered(true)} />}
            {tenentEmailEntered && <TenantLoginVerificationSecondStep close={() => setTenentEmailEntered(false)} />}
         </div>
      </div>

   </div>;
};

export default SignIn;