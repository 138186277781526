import React from 'react';
import styles from './styles.module.scss';
import SelectedValues from "./inputSelectValue";
import classes from 'classnames';
const InputSelect = ({ label, options, error = null, placeholder, onChange, isList, value, ...props }) => {
    return (
        <div className={styles.inputWrapper}>
            {label && <label className={styles.label}>{label}</label>}
            <select className={classes(styles.input, {[styles.listInputStyle]: isList})} value={isList ? value[0] : value} onChange={onChange}  {...props} >
                <option value="" disabled >{placeholder}</option>
                {options.map((v, i) => <option value={`${v?.value}`}>{v?.label}</option>)}
            </select>
            {(isList && !!value.length) &&
                <SelectedValues value={value} />
            }

            {error && <p className={styles.error}>{error}</p>}
        </div>
    )
}
export default InputSelect;

