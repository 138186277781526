import React from 'react';

const Heading = ({ text, children, Tag='h2', ...props }) => {
    return (
        <Tag {...props}>
            {text || children}
        </Tag>
    )
}

export default Heading