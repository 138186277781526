import localConfig from "./local.json";
import testConfig from "./test.json";
import dockerConfig from "./docker.json";
import stagingConfig from "./staging.json";
import productionConfig from "./production.json";
const env = process.env.REACT_APP_ENV

const configs = {
    local: localConfig,
    test: testConfig,
    docker: dockerConfig,
    staging: stagingConfig,
    production: productionConfig
}

export default configs[env] || configs["local"]