import React from 'react';
import styles from './styles.module.scss';
import FormContainer from './FormContainer';
const ThirdStep = () => {
    return <div className={styles.root}>
        <FormContainer />
    </div>
};

export default ThirdStep;
