import React from 'react';
import Modal from 'Components/Common/Modal';

const SigninVarificationSecondStep = ({ ...props }) => {
    return (
        <Modal
            title='Enter Verification Code'
            content='2-Factor Authentication enables us to ensure that your account is secure. Please enter your verification code below..'
            buttonText='Submit'
            inputs={[{ placeholder: 'Verification Code', type: 'text' }]}
            transparent={true}
            {...props}
        />
    )
};
export default SigninVarificationSecondStep;