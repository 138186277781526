import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Divider } from 'Components/Common/Divider';
import PaperClip from 'assets/images/paperClip.svg';
import FileInput from 'Components/Common/FileInput';

import styles from './styles.module.scss';
import { schema, initialValues } from './schema';

import { setOption } from "helpers/objects";
import LeftColumn from "./leftcolumn";
import RightColumn from './rightColumn';
import { graphQLClient } from '../../../../../../graphQL'
import { query } from '../../../../../../graphQL/properties/getAmenities.js';
//'../graphQL/index.js';

const FormContainer = ({ }) => {
   const [amenities, setAmenities] = useState([]);

   useEffect(() => {
      getAmenities()
   }, []);

   const getAmenities = async () => {
      const res = await graphQLClient.request(query);
      const data = res?.amenities?.edges;
      const option = setOption(data, 'node');
      setAmenities(option);
   };
   return (
      <>
         <Formik
            initialValues={initialValues({ amenities })}
            validationSchema={schema}
            validate={values => {
               const errors = {};
               return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
               setSubmitting(false);
            }}
         >
            {({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
               setFieldValue
            }) => (
               <form onSubmit={handleSubmit}>
                  <FileInput accept=".csv" label={"Import Buildings from File (CSV)"} linkText={"Download CSV Template"} icon={PaperClip} name='file' value={values.file} onChange={handleChange} error={touched.file && errors.file} onBlur={handleBlur} />
                  <Divider />
                  <div className={styles.columns}>
                     <LeftColumn values={values} errors={errors} touched={touched} handleChange={handleChange} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                     <RightColumn values={values} amenities={amenities} errors={errors} touched={touched} handleChange={handleChange} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                  </div>
                  <div className={styles.buttonsWrapper}>
                     <button className={styles.button}>Save & Add Another</button>
                     <button className={styles.button} onSubmit={handleSubmit}>Save</button>
                  </div>
               </form>
            )}
         </Formik>
      </>
   )
}
export default FormContainer;