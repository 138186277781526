import React from 'react';
import styles from './styles.module.scss';
import BreadCrumb from '../breadCrumb';
import CollapsibleComponent from './Collapsible';

const Document = ({ links, header, firstParagraph, data }) => {
    return <div className={styles.root}>
        {links && (<BreadCrumb
            links={links} currentPlace={header} />)}
        {header && (<div className={styles.header}>
            {header}
        </div>)}
        {firstParagraph && <span className={styles.firstParagraph}>
            {firstParagraph}
        </span>}
        <div className={styles.collapsibles}>
            {data.map((paragraph,i) =>
                <CollapsibleComponent header={paragraph.header} content={paragraph.content} key={i}/>
            )}
        </div>
    </div >;
};


export default Document;