import * as yup from 'yup';

export const initialValues = ({amenities}) => {
    return {
        buildingAddress: "",
        buildingCity: "",
        block: "",
        zip: "",
        propertyType: "",
        lotDimension: "",
        yearBuilt: "",
        buildingFar: "",
        managementTeam: "",
        buildingName: "",
        state: "",
        lot: "",
        floors: "",
        taxes: "",
        BuildingSQFT: "",
        maxFar: "",
        amenities: amenities,
        file: null,
        buildingImage: []
    }
}

const transformNumber = (value, originalValue) =>
    +`${originalValue}`.replace(/[^0-9.]+/g, '');

export const schema = yup.object().shape({
    file: yup.mixed()
        .nullable()
        .required('A file is required'),
    buildingAddress: yup
        .string()
        .max(200)
        .required('Enter valid address')
        .label('Building Address'),
    buildingCity: yup
        .string()
        .max(50)
        .required('Enter valid City')
        .label('Building City'),
    block: yup
        .string()
        .matches(
            /((\d+)([,.]\s*\d+)*){0,1}/,
            'Use only numbers seperated by commas or dots',
        )
        .required('Enter valid block')
        .label('Blocks'),
    zip: yup
        .string()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Not a valid ZIP code')
        .required('Enter valid Zip')
        .label('ZIP'),
    propertyType: yup
        .string()
        .required('Enter Property Type')
        .oneOf(['Home', 'Office']),
    lotDimension: yup
        .array()
        .ensure()
        .of(yup.number().transform(transformNumber))
        .min(2)
        .max(2)
        .required('Enter valid Number')
        .label('Lot Dimensions'),
    yearBuilt: yup
        .number()
        .required('Enter valid Number')
        .label('Years Built'),
    buildingFar: yup
        .number()
        .required()
        .label('Building Far'),
    managementTeam: yup
        .string()
        .required('Enter valid data')
        .oneOf(['Wifi', 'Doorman', 'Wifi', 'Doorman'])
        .label('Management Team'),
    buildingName: yup
        .string()
        .required('Enter valid name')
        .label('Bulding Name'),
    state: yup
        .string()
        .required('Enter valid state')
        .oneOf(['New york', 'New jersey'])
        .label('state'),
    lot: yup
        .number()
        .required('Enter valid number')
        .label('Lot'),
    floors: yup
        .number()
        .required('Enter valid floor number')
        .label('Floors'),
    taxes: yup
        .string()
        .required('Enter values')
        .label('Taxes'),
    BuildingSQFT: yup
        .number()
        .required('Enter valid Sq ft')
        .label('Building SQ FT'),
    maxFar: yup
        .string()
        .required('Enter valid value')
        .label('Max Far'),
    amenities: yup
        .array()
        .required('Select valid Amenities')
        .label('Amenities'),

});