import React from 'react';
import styles from './styles.module.scss';
import ToggleSwitch from 'Components/Common/toggleSwitch';
import { useInput } from 'hooks/useInput';
import { login } from 'api/auth';
import { useHistory } from 'react-router-dom';

const Column = ({children, ...props}) => <div {...props} style={{flexDirection: "column"}}>{children}</div>
const Form = ({ toggleIsManager, submit }) => {
    const [emailProps, email] = useInput();
    const [passwordProps, password] = useInput();

    const history = useHistory()

    const handleSubmit = async () => {
        const res = await login(email, password);        
        if (res?.tokenAuth?.token) return history.push("/onboarding")
    }

    return ([
        <p className={styles.header}>Log In</p>,
        <div className={styles.toggle}><ToggleSwitch leftText={'MANAGER'} rightText={'TENANT'} onChange={toggleIsManager} /></div>,
        <Column className={styles.inputs}>
            <Column >
                <input className={styles.input} placeholder="Email" type="email" {...emailProps}/>
                <input className={styles.input} placeholder="Password" type="password"  {...passwordProps}/>
            </Column>
            <button className={styles.button} onClick={handleSubmit}> Login </button>
        </Column>
    ]);
};

export default Form;