import React from 'react';
import PhoneParagraph from './Paragraph/PhoneParagraph';
import BuildingsParagraph from './Paragraph/BuildingsParagraph';
import PuzzleParagraph from './Paragraph/PuzzleParagraph';

const SecondSection = () => {
   return (
      <section id="about">
         <PhoneParagraph />
         <BuildingsParagraph />
         <PuzzleParagraph />
      </section>);
};

export default SecondSection;