import React, { useState } from 'react';
import styles from './styles.module.scss';
import x from '../../../../../assets/images/crossSquare.svg';
import check from '../../../../../assets/images/chechbox.svg'
import { CheckBox } from "Components/Common/TabListSelect/TabListButton";
import classes from 'classnames';
import Loader from '../../../../Common/Loader';

const Modal = ({ propStyles, isClose, title, subTitle, desription, isCheckBox, checkBoxLabel, buttonLabel, onClickClose, onSubmit, isLoading }) => {
    const [checked, setChecked] = useState(!isCheckBox);
    return (
        <div className={classes(styles.root, propStyles?.root)}>
            <div className={classes(styles.contentContainer, propStyles?.contentContainer)}>
                <Loader isLoading={isLoading}>
                    {isClose && (<img onClick={onClickClose} src={x} className={classes(styles.closeIcon, propStyles?.closeIcon)} alt="Close Icon" />)}
                    <div className={classes(styles.content, propStyles?.content)}>
                        {title && (<h3 className={classes(styles.title, propStyles?.title)}>{title}</h3>)}
                        {subTitle && (<h5 className={classes(styles.subTitle, propStyles?.subTitle)}>{subTitle}</h5>)}
                        {desription && (<p className={classes(styles.desription, propStyles?.desription)}>{desription}</p>)}
                        {isCheckBox && (
                            <div className={classes(styles.checkBoxContainer, propStyles?.checkBoxContainer)} onClick={() => setChecked(!checked)}>
                                <CheckBox isChecked={checked} icon={<img src={check} />} />
                                <p>{checkBoxLabel}</p>
                            </div>
                        )}
                        <button className={classes(styles.button, propStyles?.button)} onClick={() => checked && onSubmit()}>{buttonLabel}</button>
                    </div>
                </Loader>

            </div>
        </div>
    )
};
export default Modal;