import React from "react";
import TabListButton from "./TabListButton";
import classes from "classnames"
import styles from "./styles.module.scss";


 const TabListSelect =({ values, onChange, isRadio, isCheckBox, value, label, buttonWidth, className})=> {
    const _renderRadioButtons = () => {
        return (values || []).map((selectedItem, i) => {
            

            let isChecked = value && (value?.value === selectedItem?.value);
            return (
                <TabListButton
                    buttonWidth={buttonWidth}
                    key={i}
                    onButtonPress={() => onChange(selectedItem)}
                    isChecked={isChecked}
                    isRadio={isRadio}
                    isCheckBox={isCheckBox}
                    icon = {selectedItem.icon}
                    text={selectedItem.text.toString()}
                />
            );
        });
    };

    return  <div className={classes(styles.mainContainer, className)}>
                <label>{label}</label>
                {_renderRadioButtons()}
            </div>;
}


export default TabListSelect