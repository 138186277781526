import * as yup from 'yup';

export const initialValues = () => {
    return {
        file: null,
        profilePicture: [],
        fullName: "",
        lastName: "",
        phone: "",
    }
};

export const schema = yup.object().shape({
    file: yup.mixed()
        .nullable()
        .required('A file is required'),
    fullName: yup
        .string()
        .max(200)
        .required('Full name is required')
        .label('Full Name'),
    lastName: yup
        .string()
        .max(200)
        .required('Last name is required')
        .label('Last Name'),
    phone: yup
        .number()
        .required('Enter valid Phone Number')
        .label('Phone'),
});