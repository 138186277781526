import React from 'react';
import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';
import managerBackground from 'assets/images/signupManager.svg';
import landlordBackground from 'assets/images/signupLandlord.svg';

import logomark from 'assets/images/TigraWordmarkLogomark.png';
import linearGradient from 'assets/images/linearGradient.png';
import { useIsOpen } from 'hooks/useIsOpen';
import OnboardingForm from 'Containers/Auth/OnboardingForm';


const SignUp = () => {
   const {isOpen: isManager, toggle:toggleIsManager} = useIsOpen(true);
   const imagePath = isManager ? managerBackground : landlordBackground;

   return (
      <div className={styles.root}>
         <div className={styles.imageBulk}>
            <img src={imagePath} className={styles.backgroundImage} />
            <img src={linearGradient} className={styles.linearGradient} />
            <img src={logomark} className={styles.logomark} />
         </div>
         <div className={styles.formBulk}>
            <OnboardingForm isManager={isManager} toggleIsManager={toggleIsManager} />
            <NavLink className={styles.loginLink} to="/signin">Already Have an account?<span>  Login </span></NavLink>
         </div>
      </div>
   )
};

export default SignUp;
