import React,{setMenu} from 'react';
import { NavLink } from 'react-router-dom';
import arrowIcon from 'assets/images/whiteRightArrowIcon.svg';
const MenuLink = (props) => {
  const {menu, styles, handleMenu} = props;
  return (
    <>{
      menu.isPathExist ? (
      <div className={styles.decoratedLinkWrapper}>
        <NavLink onClick={handleMenu} className={styles.decoratedLink} to={menu.url} >{menu.name}</NavLink > 
        <img className={styles.linkImg} src={arrowIcon} alt="" />
      </div>
      ): ( <NavLink  onClick={handleMenu} to={menu.url} className={styles.link} >{menu.name}</NavLink>)
    }</>
  )
}

export default MenuLink