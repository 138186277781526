

import {connect} from "react-redux";
import UnitApplicationFormHome from "../../../Components/pages/UnitApplicationForm/UnitApplicationFormHome";
import toJS from "../../../hoc/toJS";
import { loadUnit } from "../../Properties/actions";
import { getUnitSelector } from "../../Properties/selectors";

const mapStateToProps = (state, {}) => {
    return {
        unit: getUnitSelector(state)
    }
}

const mapDispatchToProps = (dispatch, {}) => {
    return {
        onLoad: id => dispatch(loadUnit(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(toJS(UnitApplicationFormHome));

