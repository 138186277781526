import { gql } from 'graphql-request';
export const query = gql`
query GetAmenities(
         $after: String
         $first: Int
         $filter: String
         $type: String
       ) {
         amenities(
           after: $after
           first: $first
           name_Icontains: $filter
           amenityType: $type
         ) {
           pageInfo {
             hasNextPage
             endCursor
           }
           edges {
             node {
               id
               name
             }
           }
         }
 }
 `