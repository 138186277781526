import React from 'react';
import Modal from 'Components/Common/Modal';

const ResetPasswordThirdStep = ({ clicked, close, ...props }) => {
    return (
        <Modal
            title='Enter New Password'
            buttonText='Reset'
            inputs={[{ placeholder: 'New Password', type: "password" }, { placeholder: "Confirm New Password", type: 'password' }]}
            clicked={clicked}
            close={close}
            transparent
            link={{
                regularText: 'didn’t receive the email?',
                greyText: 'resend'
            }}
            {...props}
        />
    )
};
export default ResetPasswordThirdStep;