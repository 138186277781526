import React, {useState} from "react";
import Slider from "react-slick";
import Plan from "../Plan";
import styles from './styles.module.scss';

const MobileCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
      className: styles.slides,
      centerMode: true,
      infinite: true,
      // slidesToShow: 1,
      swipeToSlide: true,
    };

    return (
        <Slider {...settings} afterChange={index => setActiveIndex(index)}>
          <Plan isActive={activeIndex == 0} planName="BASIC" price="Free" conditions={[{ amount: 100, content: 'connections' }, { amount: 3, content: 'features' }, { amount: 10, content: 'requests per month' }]} />
          <Plan isActive={activeIndex == 1} planName="PREMIUM" price="$50" smallPriceText="/month" conditions={[{ amount: 100, content: 'connections' }, { amount: 3, content: 'features' }, { amount: 10, content: 'requests per month' }]} />
          <Plan isActive={activeIndex == 2} planName="PRO" price="$100" smallPriceText="/month" conditions={[{ amount: 100, content: 'connections' }, { amount: 3, content: 'features' }, { amount: 10, content: 'requests per month' }]} />
        </Slider>
    );
}

export default MobileCarousel