import React, { useEffect, useState } from 'react';
import InputSelect from 'Components/Common/InputSelect';
import Input from 'Components/Common/Input';
import styles from './styles.module.scss';

const RightColumn = ({ values, amenities, touched, errors, handleChange, setFieldValue, handleBlur }) => {
    return (
        <div className={styles.rightColumn}>
            <Input name="buildingName" value={values.buildingName} onChange={handleChange} error={touched.buildingName && errors.buildingName} onBlur={handleBlur} label={'Building Name'} placeholder='Building' />
            <InputSelect onChange={handleChange} value={values.state} id="state" name="state" error={touched.state && errors.state} options={[{ label: 'New york', value: 1 }, { label: 'New jersey', value: 2 }]} label={'State'} placeholder='Select State' />
            <Input name="lot" value={values.lot} onChange={handleChange} error={touched.lot && errors.lot} onBlur={handleBlur} label={'Lot'} placeholder='Lot' />
            <Input name="floors" value={values.floors} onChange={handleChange} error={touched.floors && errors.floors} onBlur={handleBlur} label={'Number of Floors'} placeholder='5' />
            <Input name="taxes" value={values.taxes} onChange={handleChange} error={touched.taxes && errors.taxes} onBlur={handleBlur} label={'Taxes'} placeholder='$86,583.91 ' />
            <Input name="BuildingSQFT" value={values.BuildingSQFT} onChange={handleChange} error={touched.BuildingSQFT && errors.BuildingSQFT} onBlur={handleBlur} label={'Building SQ FT'} placeholder='SQ FT' />
            <Input name="maxFar" value={values.maxFar} onChange={handleChange} error={touched.maxFar && errors.maxFar} onBlur={handleBlur} label={'Max Far'} placeholder='3' />
            <InputSelect onChange={handleChange} error={touched.managementTeam && errors.managementTeam} value={values.managementTeam} id="managementTeam" name="managementTeam" options={[{ label: 'Wifi', value: 1 }, { label: 'Doorman', value: 2 }, { label: 'Wifi', value: 3 }, { label: 'Doorman', value: 4 }]} label={'Management Team'} placeholder='Select Member' />
            <InputSelect onChange={(event) => setFieldValue('amenities', [...values.amenities, event.target.value])} value={values.amenities} isList={true} error={touched.amenities && errors.amenities} id="amenities" name="amenities" options={amenities} label={'List of all Amenities'} placeholder='Pick...' />
        </div>
    )
}

export default RightColumn;