import React,  { useMemo } from 'react';
import styles from './styles.module.scss';
import IphoneDownload from 'assets/images/iphone.svg'
import AndroidPhone from 'assets/images/android.png'

import { ANDROID, IOS } from 'helpers/device';
import config from 'config/config';
const { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } = config;


const DEVICE_VARS = {
    [IOS]: {
        downloadImage: IphoneDownload,
        downloadUrl: IOS_DOWNLOAD_LINK
    },
    [ANDROID]: {
        downloadImage: AndroidPhone,
        downloadUrl: ANDROID_DOWNLOAD_LINK
    }
}

const DownloadAppButton = ({ os }) => {
    const { downloadImage, downloadUrl } = useMemo(() => DEVICE_VARS[os], [os])

    return (
        <a href={downloadUrl} target="_blank">
            <img className={styles.img} src={downloadImage} alt="" />
        </a>
    )
}

export default DownloadAppButton;