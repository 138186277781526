import React from 'react';
import classes from 'classnames';
import styles from "./styles.module.scss";

const defaultDivider = <div className={styles.divider} />

const DefaultTabHeaderComponent = ({ tab, index, isActive }) => {
    return (
        <div className={classes({[styles.sectionContainer]: true, [styles.active]: isActive})}>
            <div>{index + 1}</div>
            <div>{tab?.text}</div>
        </div>
    )
}

const TabularHeader = ({ tabs = [], activeIndex, TabHeaderComponent = DefaultTabHeaderComponent, divider = defaultDivider, containerClass }) => {
    return (
        <div className={classes(containerClass, styles.container)}>
            {tabs.map((tab, index) => (
                <>
                    <TabHeaderComponent tab={tab} index={index} isActive={activeIndex === index} />
                    {divider}
                </>
            ))}
        </div>
    )
}

export default TabularHeader;