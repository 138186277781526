import React from 'react';
import Card from 'Components/Common/Card';

const ResetPasswordSecondStep = ({ close, clicked, ...props }) => {
    return (
        <Card
            title='Check Your Mailbox'
            content='We have sent you a link to reset your password.'
            buttonText='Open Email'
            close={close}
            clicked={clicked}
            transparent
            {...props}
        />
    )
};
export default ResetPasswordSecondStep;