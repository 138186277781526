import React from 'react';
import styles from './styles.module.scss';
import Backdrop from '../Backdrop';
import x from 'assets/images/greyX.svg';

const Card = ({ close, clicked, title, content, buttonText, transparent = false, ...props }) => {
    return (
        <div className={styles.root}>
            <Backdrop close={close} transparent={transparent} />
            <div className={styles.rootContent}>
                <img onClick={close} src={x} className={styles.x} alt="" />
                <span className={styles.title}> {title} </span>
                <span className={styles.content}>{content} </span>
                <button className={styles.button} onClick={clicked}>{buttonText} </button>
            </div>
        </div>
    )
};

export default Card;