import React from 'react';
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";
import { history } from "./Redux/store";
import LandingPage from './Components/pages/LandingPage';
import SignUp from './Components/pages/SignUp';
import SignIn from './Components/pages/SignIn';
import PrivacyPolicy from './Components/pages/LandingPage/PrivacyPolicy';
import TermsOfService from './Components/pages/LandingPage/TermsOfService';
import Onboarding from './Components/pages/Onboarding';
import UnitApplicationForm from './Containers/UnitApplicationForm';



const Routes = () => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/signup" component={SignUp} />
                <Route path="/signin" component={SignIn} />
                <Route path="/privacyPolicy" component={PrivacyPolicy} />
                <Route path="/termsOfService" component={TermsOfService} />
                <Route path="/onboarding" component={Onboarding} />
                <Route path='/unit-application/:unitId' component={UnitApplicationForm} />
                <Route path="/" component={LandingPage} />
            </Switch>
        </ConnectedRouter>
    );
};

export default Routes;