import React from 'react';
import Paragraph from '../index';
import buildingsGif from 'assets/images/buildings.gif';

const microcopy = {
    title: 'Easy & efficient property management' ,
    mainText: 'TIGRA is the first & only holistic, experience-based property management platform, created & founded by an owner & manager, for owners & managers. Our goal? Improving property performance through clear & easy communication.',
    bottomText: 'Seamless Success. Every Single Time!',
}

const BuildingsParagraph = () => (
    <Paragraph 
        gif={buildingsGif} 
        title={microcopy.title}
        mainText= {microcopy.mainText}
        bottomText={microcopy.bottomText}
        leftToRight={false} 
    />

)

export default BuildingsParagraph;