import React from 'react';
import styles from './styles.module.scss';
import Input from "../Input";

const ImageInput = ({type, accept, name, value, label, error, handleClick, inputRef, icon, onChange, profileIcon, inputWrapperStyle, inputStyle }) => {
    return (
        <>
            <Input type={type} accept={accept} onChange ={onChange} name={name} label={label} error={error} handleClick={handleClick} inputRef={inputRef} icon={icon} inputWrapperStyle={inputWrapperStyle} inputStyle={inputStyle} profileIcon={profileIcon} />           
            {(!!value.length && !profileIcon) && <div className={styles.imageWrapper}>
                {value.map((file, i) => (
                    <img src={URL.createObjectURL(file)} alt={file.name} className={styles.image} />
                ))}
            </div>}
        </>
    )
};

export default ImageInput;