import { graphQLClient } from "../graphQL";
import { changePasswordGql, loginGql, registerUserGql } from "../graphQL/auth/register";

export const registerUser =  (data) => {
    return  graphQLClient.request(registerUserGql, { data });
}

export const changeUserPassword = (token, data) => {
    return graphQLClient.request(changePasswordGql, data, {Authorization: `JWT ${token}`})
} 

export const login = (email, password) => {
    return graphQLClient.request(loginGql, {email, password})
}