import React from 'react';
import Card from 'Components/Common/Card';

const ApplicationSubmittedModal = ({ close, OpenEmailClicked }) => {
    return (
        <Card
            title='Application Submitted'
            content='A team member from PM will contact you shortly.'
            buttonText='Back to Homepage'
            close={close}
            clicked={OpenEmailClicked}
        />
    )
};

export default ApplicationSubmittedModal;