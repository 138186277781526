import React from 'react';
import styles from './styles.module.scss';
import iphone from 'assets/images/Iphone.png';
import NavBar from '../NavBar';
import arrowDown from 'assets/images/arrowDown.svg'
import screen from 'assets/images/screen.png';
import { isMobile } from 'hooks/useIsMobile'
import { DEVICE_OS } from 'helpers/device';
import DownloadAppButton from '../../../DownloadAppButton';
import { ANDROID, IOS } from '../../../../helpers/device';

const FirstSection = () => {
    return <div className={styles.root}>
        <div className={styles.rootWrap}>
            <NavBar />
            <div className={styles.bulks}>
                <div className={styles.leftBulk}>
                    <div className={styles.leftBulkBox}>
                        <p className={styles.title}>Empowered<br /> property<br /> performance </p>
                        <p className={styles.subtitle}>TIGRA delivers the ultimate real estate management platform built to increase efficiency, streamline operations, minimize cost & drive profits. All in one easy to use powerful tool.</p>
                        {
                            (isMobile && DEVICE_OS) ? <DownloadAppButton os={DEVICE_OS} /> :
                                (
                                    <>
                                        <div className={styles.buttonsContainer}>
                                            <a href="#features" className={styles.darkButton}>Learn More
                                                <img src={arrowDown} className={styles.buttonIcon} alt="" />
                                            </a>
                                        </div>
                                        <div className={styles.desktopDownloadContainer}>
                                            <DownloadAppButton os={IOS} />
                                            <DownloadAppButton os={ANDROID} />
                                        </div>
                                    </>
                                )
                        }
                    </div>
                </div>
                <div className={styles.rightBulk}>
                    <div className={styles.screenWrapper}>
                        <div className={styles.screenBox}>
                            <div className={styles.screenOverlay}>
                                <img src={screen} className={styles.screenFirstLayer} />
                            </div>
                            <img src={iphone} className={styles.iphone} alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className={styles.mobileIphoneBox}>
            <img src={iphone} alt="" />
        </div>

    </div >;
};


export default FirstSection;