import React, { useState } from "react";
import styles from './styles.module.scss';
import classes from 'classnames';

const ToggleSwitch = ({ value = false, leftText = 'off', rightText = 'on', backgroundColor, onChange, moverClass, ...props }) => {
    const [isOn, setIsOn] = useState(value);
    const toggle = () => {
        setIsOn(isOn => !isOn);
        onChange && onChange(!isOn);
    };
    const right = isOn ? '0' : '50%';
    return (
        <div onClick={toggle} className={styles.container} style={{ ...props }}>
            <span className={classes(styles.zIndex, {
                [styles.gray]: isOn,
                [styles.dark]: !isOn
            })}>{leftText}</span>
            <span className=
                {classes(styles.zIndex, {
                    [styles.dark]: isOn,
                    [styles.gray]: !isOn
                })}>{rightText}</span>
            <span className={classes(styles.mover, moverClass)} style={{ backgroundColor: backgroundColor, right: right }} />
        </div>
    )
};

export default ToggleSwitch;