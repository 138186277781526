import { fromJS } from "immutable"
import { LOAD_UNIT_SUCCESS, SUBMIT_TENANT_UNIT_APPLICATION, TENANT_APPLICATION_SUBMISSION_, UNIT } from "./consts";
import { IS_SUCCESS } from "../../Redux/consts";

const initialState = fromJS({
        [UNIT]: {},
});


const reducer = (state, action) => {
    switch (action.type) {
        case LOAD_UNIT_SUCCESS:
            return state.set(UNIT, fromJS(action.data))
        case TENANT_APPLICATION_SUBMISSION_ + IS_SUCCESS:
            return state.set(TENANT_APPLICATION_SUBMISSION_ + IS_SUCCESS, action.isSuccess)
        default: 
            return initialState
    }
}

export default reducer