import React from 'react';
import styles from './styles.module.scss';
import dot from 'assets/images/secondary2Dot.svg';
import classes from 'classnames';

const NavigationTitles = ({ leftText, rightText, isManagers = true, toggleTabs }) => {
   return (
      <div className={styles.root}>
         <div className={styles.itemWrapper}>
            <span className={
               classes(styles.navigationItem, {
                  [styles.darkGray]: isManagers,
                  [styles.lightGray]: !isManagers
               })
            } onClick={() => toggleTabs(true)}>{leftText}</span>
            {isManagers && <img src={dot} className={styles.dot} alt="" />}
         </div>
         <div className={styles.itemWrapper}>
            <span className={
               classes(styles.navigationItem, {
                  [styles.lightGray]: isManagers,
                  [styles.darkGray]: !isManagers
               })}
               onClick={() => toggleTabs(false)}>{rightText}</span>
            {!isManagers && <img src={dot} className={styles.dot} alt="" />}
         </div>
      </div>);
};

export default NavigationTitles;