import * as yup from 'yup';

export const initialValues = () => {
    return {
        name: "",
        userLimit: ""
    }
};

export const schema = yup.object().shape({
    name: yup
        .string()
        .max(200)
        .required('Name is required')
        .label('Name'),
    userLimit: yup
        .string()
        .max(200)
        .required('User limit is required')
        .label('User Limits'),

});