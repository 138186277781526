import React from 'react';
import styles from '../styles.module.scss';
import Document from '../Document';
import MainLayout from 'Components/Layout/MainLayout';
import items  from './items';
import { romanize } from '../../../../helpers/numbers';
import { useSearchParams } from '../../../../hooks/useSearchParams';

const PrivacyPolicy = () => {
    const {value:mode} =useSearchParams('mode');
    const webview_content_only = mode == 'webview_content_only';
    const itemsList = items.map((item,i) => ({...item, header:  romanize(i+1) + '. ' + item.header}));
    return webview_content_only ?
        <div className={styles.root} style={{paddingRight:'1em'}}>
            <div className={styles.wideWrap}>
                <Document
                    data={itemsList}
                    firstParagraph=""
                />
            </div>
        </div>
        :
        <MainLayout>
            <div className={styles.root}>
                <div className={styles.wrap}>
                    <Document
                        links={[{ displayName: 'Home', url: '/' }]}
                        header='Terms Of Service'
                        data={itemsList}
                        firstParagraph=""
                    />
                </div>
            </div>
        </MainLayout>
};

export default PrivacyPolicy;