import React from 'react';
import styles from './styles.module.scss';

const FileInput = ({ accept,icon,value,label,linkText,error,inputClass,...props }) => {
   return (
      <div className={styles.importContainer}>
         <div className={styles.buttonsWrapper}>
            <button className={styles.importButton} >
               <span>{(!value || value == '')?  label:value} </span><img src={icon} />
            </button>
            <input type='file' accept={accept} {...props} className={styles.custom_file_input} />
         </div>
         <a className={styles.downloadLink}>{linkText}</a>
         {error && <p className={styles.error}>{error}</p>}
      </div>
   )
};

export default FileInput;
