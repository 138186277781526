import React from 'react';
import MobileMenu from './Menus/Mobile';
import DesktopMenu from './Menus/Desktop'
import menus from './MenusItems';
import { isMobile } from 'hooks/useIsMobile';
import classes from 'classnames';
import styles from './styles.module.scss';

const NavBar = ({ withBackground = false }) => {
    return (
        <>
            <div
                className={classes({[styles.withBackground]: withBackground})}
            >
                {isMobile ? <MobileMenu menus={menus}/> : <DesktopMenu menus={menus}/>}
            </div>
        </>
    );
};

export default NavBar;