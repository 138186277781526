import React from 'react';
import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';
import ChevronRight from '@mui/icons-material/ChevronRight';


const BreadCrumb = ({ links, currentPlace }) => {
    return <div className={styles.root}>
        {links.map((link,i) =>
        (<div key={i} className={styles.arrowBox}>
            <NavLink className={styles.link} to={link.url} >{link.displayName}</NavLink >
            <ChevronRight  className={styles.arrow}/>
        </div>)
        )}
        < span className={styles.currentPlace}> {currentPlace} </span>
    </div>;
};


export default BreadCrumb;